import LoggerClass from "@/util/Logger";
import * as Assertions from "@/realize-shared/Assertions";
import Tailwind from "@/util/Tailwind";

declare global
{
    const Logger: typeof LoggerClass;
    const AssertTrue: typeof Assertions.isTrue;
    const AssertFalse: typeof Assertions.isFalse;
    const AssertIsset: typeof Assertions.isNonNullish;
    const Wind: typeof Tailwind;
}

// @ts-expect-error setting global
globalThis.Logger = LoggerClass;
// @ts-expect-error setting global
globalThis.Wind = Tailwind;
// @ts-expect-error setting global
globalThis.AssertIsset = Assertions.isNonNullish;
// @ts-expect-error setting global
globalThis.AssertTrue = Assertions.isTrue;
// @ts-expect-error setting global
globalThis.AssertFalse = Assertions.isFalse;
