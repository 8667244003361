type Name = string;

type URI = string;

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
type Weight = 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;

type Style = "normal" | "italic" | "oblique";

type Weights = Partial<Record<Weight, URI>>;

type Styles = Partial<Record<Style, Weights>>;

type Fonts = Record<Name, Styles>;

export const load = async (fonts: Fonts) =>
{
    const promises: Promise<unknown>[] = [];

    const element = document.createElement("div");

    element.style.position = "fixed";
    element.style.top = "100%";
    element.style.left = "100%";
    element.style.height = "1px";
    element.style.width = "1px";
    element.style.overflow = "hidden";

    for (const [name, styles] of Object.entries(fonts))
    {
        for (const [style, weights] of Object.entries(styles))
        {
            for (const [weight, uri] of Object.entries(weights))
            {
                const url = `url(${uri}`;

                const desc = {
                    weight,
                    style,
                };

                promises.push(
                    new FontFace(name, url, desc)
                        .load()
                        .then(
                            (font) => document.fonts.add(font),
                        )
                        .then(() =>
                        {
                            const line = document.createElement("p");

                            line.textContent = `${name}${weight}${style}`;

                            line.style.fontFamily = name;
                            line.style.fontStyle = style;
                            line.style.fontWeight = weight;

                            element.append(line);
                        }),
                );
            }
        }
    }

    document.body.append(element);

    await Promise.all(promises);

    await document.fonts.ready;

    element.remove();
};
