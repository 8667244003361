import CodeEntry, { type CodeEntryRef } from "@/components/CodeEntry";
import { DialogButton } from "@/components/shared/DialogButton";
import logo from "@/img/logo.svg";
import viewerBackground from "@/img/backgrounds/viewer-background.webp";
import {ReactComponent as TopDecal} from "@/img/viewer/landing-top-sliver.svg";
import {ReactComponent as BottomDecal} from "@/img/viewer/landing-bottom-sliver.svg";
import { useRef, useState } from "react";
import {ReactComponent as DownloadOnApple} from "@/web/img/download-apple.svg";
import Jobo from "@/img/jobo-beta.webp";
import { useNavigate } from "react-router-dom";
import { formatRoomCode } from "@/util/Util";
import SendViewGameReq from "@/api/ViewerRequests";

export default function LandingPage()
{
    const navigate = useNavigate();
    const testCode = (roomID: string) => Boolean(roomID.match(/^[A-Z]{6}$/u));

    const onSubmit = async (roomID: string): Promise<string | null> =>
    {
        if (!testCode(roomID)) return "Room not found";

        try
        {
            await SendViewGameReq(roomID, null);

            return null;
        }
        catch
        {
            return "Room not found";
        }
    };

    const codeRef = useRef<CodeEntryRef>(null);
    const [error, setError] = useState("");

    const submit = () =>
    {
        setError("");

        AssertIsset(codeRef.current, "where room code ref?");

        const rawRoomID = codeRef.current.value;

        const roomID = rawRoomID.replaceAll("-", "");

        onSubmit(roomID)
            .then((error) =>
            {
                if (error)
                {
                    setError(error);
                }
                else
                {
                    navigate(formatRoomCode(roomID));
                }
            })
            .catch(err => void Logger.log("ERROR:", err));
    };

    return (
        <div
            className={Wind.style({
                height: "h-full",
                display: "flex",
                alignItems: "items-center",
                justifyContent: "justify-center"
            }).class}
            style={{
                backgroundImage: `url(${viewerBackground})`,
                backgroundSize: "cover",
                backgroundPosition: "center"
            }}
        >
            <div
                className={Wind.style({
                    height: "h-full",
                    width: "w-1/2",
                    display: "flex",
                    flexDirection: "flex-col",
                    alignItems: "items-center",
                    justifyContent: "justify-center",
                }).class}
            >
                <img
                    src={logo}
                    className={Wind.style({
                        width: "w-full",
                        maxWidth: "max-w-[37.5rem]",
                        marginBottom: "mb-12",
                    }).class}
                />
                <div
                    className={Wind.style({
                        display: "flex",
                        flexDirection: "flex-col",
                        width: "w-full",
                        maxWidth: "max-w-[26rem]",
                        marginLeft: "ml-auto",
                        marginRight: "mr-auto",
                        position: "relative",
                        "::before": {
                            position: "before:absolute",
                            display: "before:block",
                            height: "before:h-full",
                            width: "before:w-full",
                            inset: "before:inset-0",
                            backgroundColor: "before:bg-white",
                            transformScaleY: "before:scale-y-95",
                            transformScaleX: "before:scale-x-105",
                            zIndex: "before:z-[1]",
                        }
                    }).class}
                >
                    <TopDecal
                        className={Wind.style({
                            width: "w-full",
                            flex: "flex-[0_0_1.125rem]",
                            position: "relative",
                            zIndex: "z-10"
                        }).class}
                    />
                    <div
                        className={Wind.style({
                            display: "flex",
                            flexDirection: "flex-col",
                            alignItems: "items-center",
                            height: "h-full",
                            width: "w-full",
                            padding: "p-12",
                            backgroundColor: "bg-dark-blue",
                            position: "relative",
                            zIndex: "z-10",
                        }).class}
                    >
                        <div
                            className={Wind.style({
                                maxWidth: "max-w-[20rem]",
                                textAlign: "text-center",
                            }).class}
                        >
                            <h1
                                className={Wind.style({
                                    alignSelf: "self-start",
                                    fontFamily: "font-poppins",
                                    color: "text-white",
                                    fontSize: "text-[2rem]",
                                    fontWeight: "font-extrabold",
                                    marginBottom: "mb-6",
                                    textAlign: "text-left"
                                }).class}
                            >
                                Spectate Game
                            </h1>
                            <CodeEntry
                                ref={codeRef}
                                error={error}
                                className={Wind.style({
                                    width: "w-full",
                                    marginBottom: "mb-6"
                                }).class}
                                onChanged={() => void setError("")}
                                onEnter={() => void submit()}
                            />
                            <DialogButton
                                text="Join"
                                color="sharp-red"
                                onClick={() => void submit()}
                                className={Wind.style({
                                    marginBottom: "mb-14",
                                    width: "w-28",
                                    height: "h-14",
                                    fontFamily: "font-poppins",
                                    fontSize: "text-xl",
                                    lineHeight: "leading-6",
                                }).class}
                            />
                            <div
                                className={Wind.style({
                                    display: "flex",
                                    justifyContent: "justify-center",
                                    width: "w-full",
                                }).class}
                            >
                                <a
                                    href="https://testflight.apple.com/join/63ZnughQ"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <DownloadOnApple />
                                </a>
                            </div>
                        </div>
                    </div>
                    <BottomDecal
                        className={Wind.style({
                            width: "w-full",
                            height: "h-auto",
                            position: "relative",
                            zIndex: "z-10"
                        }).class}
                    />
                </div>
            </div>
            <div className="fixed right-0 bottom-0">
                <img
                    src={Jobo}
                    className={
                        Wind.style({
                            width: "w-[6.438rem]",
                            height: "h-auto",
                            transformTranslateY: "translate-y-5"
                        }).class
                    }
                />
            </div>
        </div>
    );
}
