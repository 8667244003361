export type Types =
    | "undefined"
    | "boolean"
    | "number"
    | "bigint"
    | "string"
    | "symbol"
    | "object"
    | "null"
    | "array"
    | "function"
    | "class";

export const getType = (value: unknown): Types =>
{
    const type = typeof value;

    switch (type)
    {
        case "undefined":
        case "boolean":
        case "number":
        case "bigint":
        case "string":
        case "symbol":
            return type;
        case "object":
            if (!value) return "null";

            if (Array.isArray(value)) return "array";

            return type;
        case "function":
            if (isClass(value)) return "class";

            return type;

        // no default
    }
};

// ----------

export type MaybePromise<T> = Promise<T> | T;

// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export type Maybe<T> = T | undefined | void;

// ----------

export const isString = (raw: unknown): raw is string =>
{
    return typeof raw === "string";
};

export const asString = (raw: unknown) =>
{
    if (isString(raw)) return raw;

    throw new Error("Not a string");
};

// ----------

export const isArray = (raw: unknown): raw is unknown[] =>
{
    return Array.isArray(raw);
};

export const asArray = (raw: unknown) =>
{
    if (isArray(raw)) return raw;

    throw new Error("Not an array");
};

// ----------

export const isStringArray = (raw: unknown): raw is string[] =>
{
    try
    {
        asArray(raw).forEach(asString);

        return true;
    }
    catch (e)
    {
        return false;
    }
};

export const asStringArray = (raw: unknown) =>
{
    if (isStringArray(raw)) return raw;

    throw new Error("Not a string array");
};

// ----------

export const isJSONObject = (raw: unknown): raw is Record<string, unknown> =>
{
    if (typeof raw !== "object") return false;
    if (!raw) return false;
    if (Array.isArray(raw)) return false;

    return true;
};

export const asJSONObject = (raw: unknown) =>
{
    if (isJSONObject(raw)) return raw;

    throw new Error("Not an object");
};

// ----------

export type Class = new(...args: unknown[]) => unknown;

export const isClass = (raw: unknown): raw is Class =>
{
    if (!raw) return false;
    if (typeof raw !== "function" && typeof raw !== "object") return false;

    const proto = (() =>
    {
        if (!("prototype" in raw)) return false;

        if (!raw.prototype) return false;

        const temp = raw.prototype.constructor;

        if (typeof temp !== "function") return false;

        return temp.toString().startsWith("class");
    })();

    return proto || raw.constructor.toString().startsWith("class");
};

export const asClass = (raw: unknown): Class =>
{
    if (isClass(raw)) return raw;

    throw new Error("Not a class");
};

// ----------

const areObjectsSame = (
    input: Record<string, unknown>,
    proto: Record<string, unknown>,
    exact: boolean,
): boolean =>
{
    const inputKeys = new Set(Object.keys(input));
    const protoKeys = new Set(Object.keys(proto));

    if (inputKeys.size < protoKeys.size) return false;

    if (exact && (inputKeys.size !== protoKeys.size)) return false;

    for (const key of protoKeys)
    {
        if (!inputKeys.has(key)) return false;

        const inputValue = input[key];
        const protoValue = proto[key];

        const inputType = getType(inputValue);
        const protoType = getType(protoValue);

        if (inputType !== protoType) return false;

        if (inputType === "object" || inputType === "class")
        {
            if (!areObjectsSame(
                asJSONObject(inputValue),
                asJSONObject(protoValue),
                exact,
            )) return false;
        }
    }

    return true;
};

const templateTypes: Record<string, Record<string, unknown>> = {};

export const isSameType = <T extends object>(
    someObject: unknown,
    Constructor: new () => T,
    allowSubtype = false,
): someObject is T =>
{
    if (!isJSONObject(someObject)) return false;

    const className = Constructor.name;

    let template: Record<string, unknown>;


    if (className in templateTypes)
    {
        template = templateTypes[className];
    }
    else
    {
        template = new Constructor() as Record<string, unknown>;
        templateTypes[className] = template;
    }

    return areObjectsSame(someObject, template, !allowSubtype);
};

export const asSameType = <T extends object>(
    someObject: unknown,
    Constructor: new () => T,
) =>
{
    if (isSameType(someObject, Constructor)) return someObject;

    throw new Error("Not a class");
};

export const asType = <T>(raw: unknown) =>
{
    return raw as T;
};

export type Aliased<T> = T & Omit<T, never>;
