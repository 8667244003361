import { Clipboard as clipboard } from "@capacitor/clipboard";

/**
 * Copies string to computer's clipboard
 * @param text the text to copy
 * @returns Promise that resolves to true iff the copy operation succeeds, false otherwise
 */
export const copy = async (text?: string) =>
{
    if (!text) return false;

    await clipboard.write({ "string": text }).catch(Logger.warn);

    return true;
};
