import { Type } from "@/realize-shared/model/Event";
import type { Resource, Text, Reason, ObjectID, } from "@/realize-shared/model/Types";
import type { Round, Scoreboard } from "../types/Game";
import type { Player } from "@/game/types/Game";
import type { ThemePack } from "../ShopItem";

export enum EventState
{
    STATIC,
    STICKY,
    ACTIVE,
}

type ProcessedEventBase<EventType extends Type> = {
    type: EventType,
    id: ObjectID,
    time: number,
    round: Round | null,
    state: EventState,
    theme: ThemePack,
};

type ProcessedEventMapping = {
    [EventType in Type]: ProcessedEventBase<EventType> & Record<string, unknown>;
};

export interface ProcessedEventMap extends ProcessedEventMapping
{
    [Type.GAME_START]: {
        type: Type.GAME_START,
        id: ObjectID,
        time: number,
        round: null,
        state: EventState,
        theme: ThemePack,

        issue: number,
        serverVersionInfo: Record<string, unknown>,
    },

    [Type.PLAYER_JOIN]: {
        type: Type.PLAYER_JOIN,
        id: ObjectID,
        time: number,
        round: null,
        state: EventState.STATIC,
        theme: ThemePack,

        clientVersionInfo: Record<string, unknown>,
        player: Player,
    },

    [Type.CHAT]: {
        type: Type.CHAT,
        id: ObjectID,
        time: number,
        round: Round | null,
        state: EventState.STATIC,
        theme: ThemePack,

        sender: Player,
        message: Text,

        isSameAsPrevious: boolean,
    },

    [Type.PLAYER_LEAVE]: {
        type: Type.PLAYER_LEAVE,
        id: ObjectID,
        time: number,
        round: Round | null,
        state: EventState.STATIC,
        theme: ThemePack,

        reason: Reason,
        host: Player,
        player: Player,
    },

    [Type.GAME_END]: {
        type: Type.GAME_END,
        id: ObjectID,
        time: number,
        round: Round | null,
        state: EventState.STATIC,
        theme: ThemePack,

        reason: Reason,
    },

    [Type.SETTINGS_CHANGE]: {
        type: Type.SETTINGS_CHANGE,
        id: ObjectID,
        time: number,
        round: null,
        state: EventState.STATIC,
        theme: ThemePack,

        changes: Record<string, unknown>,
    },

    [Type.ROUND_START]: {
        type: Type.ROUND_START,
        id: ObjectID,
        time: number,
        round: Round,
        state: EventState,
        theme: ThemePack,

        editor: Player,
    },

    [Type.HEADLINE_CREATE]: {
        type: Type.HEADLINE_CREATE,
        id: ObjectID,
        time: number,
        round: Round,
        state: EventState,
        theme: ThemePack,

        headline: Text,
    },

    [Type.PHOTO_SUBMIT]: {
        type: Type.PHOTO_SUBMIT,
        id: ObjectID,
        time: number,
        round: Round,
        state: EventState.STATIC,
        theme: ThemePack,

        player: Player,
        photo: Resource,
    },

    [Type.VOTE_START]: {
        type: Type.VOTE_START,
        id: ObjectID,
        time: number,
        round: Round,
        state: EventState,
        theme: ThemePack,

        submissions: { player: Player, photo: Resource }[],
    },

    [Type.VOTE_RESULT]: {
        type: Type.VOTE_RESULT,
        id: ObjectID,
        time: number,
        round: Round,
        state: EventState,
        theme: ThemePack,

        winner: Player,
        photo: Resource,
        scoreboard: Scoreboard,
    },

    [Type.ROUND_SKIP]: {
        type: Type.ROUND_SKIP,
        id: ObjectID,
        time: number,
        round: Round,
        state: EventState.STATIC,
        theme: ThemePack,

        reason: Reason,
    },
}
