import {ReactComponent as ClockIcon} from "@/web/img/clock.svg";
import { S_IN_M } from "@/realize-shared/model/Time";
import type { ReactNode } from "react";
import { useGameContext } from "@/game/GameContext";

const ClockWrapper = ({
    clock,
}: {
    clock: number;
}) =>
{
    const minutes = Math.floor(clock / S_IN_M); // .toFixed().padStart(1, "0");
    const seconds = Math.floor(clock % S_IN_M); // .toFixed().padStart(1, "0");

    const Char = ({n}: { n: ReactNode }) => (
        <span className="inline-block min-w-[1ch]">
            {n}
        </span>
    );

    const Fixed = ({n, t}: { n: number, t: string }) => (
        <>
            <Char n={n < 10? "": Math.floor(n / 10)} />
            <Char n={Math.floor(n % 10)} />
            {t}
        </>
    );

    return (
        <div
            className={Wind.style({
                display:"flex",
                backgroundColor: "bg-[#1B2F4E]",
                color: "text-white",
                padding: "p-1",
                borderRadius: "rounded-xl",
            }).class}
        >
            <ClockIcon className="w-6 h-6" />
            <span
                className={Wind.style({
                    width: "w-24",
                    textAlign: "text-center",
                }).class}
            >
                {(minutes > 0) && (
                    <Fixed
                        n={minutes}
                        t="m "
                    />
                )}
                <Fixed
                    n={seconds}
                    t="s"
                />
            </span>
        </div>
    );
};

export default function PlayerSubmissionClock()
{
    const {clock} = useGameContext();

    return (
        <div
            className={Wind.style({
                display: "flex",
                flexDirection: "flex-col",
                alignItems: "items-center",
                gap: "gap-2",
            }).class}
        >
            <span
                className={Wind.style({
                    fontFamily: "font-poppins",
                    fontSize: "text-xl",
                    fontWeight: "font-medium",
                    lineHeight: "leading-6",
                    color: "text-parchment",
                }).class}
            >
                Waiting for submissions
            </span>
            <ClockWrapper clock={clock} />
        </div>
    );
}
