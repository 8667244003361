import type { Type } from "@/realize-shared/model/Event";
import PlayerStatusMessage from "@/chat/components/PlayerStatusMessage";
import type { GameNodeProps } from "../GameNodeProps";

const PlayerLeaveMessage = (
    event: GameNodeProps<Type.PLAYER_LEAVE>,
) => (
    <PlayerStatusMessage
        {...event}
        text="$PLAYER has left"
    />
);

export default PlayerLeaveMessage;
