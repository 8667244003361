import * as Random from "@/realize-shared/Random";

export const shuffle = <T>(array: T[]): void =>
{
    let index = array.length;
    let temp: number;

    while (index)
    {
        temp = Random.integer(index);
        index--;

        [
            array[index], array[temp],
        ] = [
            array[temp], array[index],
        ];
    }
};

export const zip = <A, B>(a: A[], b: B[]): [A, B][] =>
{
    if (a.length !== b.length) throw new Error("Array length mismatch");

    return a.map((a, i) => [a, b[i]]);
};

export const cyrb53 = (str: string, seed = 0) =>
{
    // cyrb53 (c) 2018 bryc (github.com/bryc)
    // License: Public domain. Attribution appreciated.
    // A fast and simple 53-bit string hash function with decent collision resistance.
    // Largely inspired by MurmurHash2/3, but with a focus on speed/simplicity.


    /* eslint-disable @typescript-eslint/no-magic-numbers*/

    let hs1 = 0xdeadbeef ^ seed,
        hs2 = 0x41c6ce57 ^ seed;

    for (let i = 0, char; i < str.length; i++)
    {
        char = str.charCodeAt(i);

        hs1 = Math.imul(hs1 ^ char, 2654435761);
        hs2 = Math.imul(hs2 ^ char, 1597334677);
    }

    hs1 = Math.imul(hs1 ^ (hs1 >>> 16), 2246822507);
    hs1 ^= Math.imul(hs2 ^ (hs2 >>> 13), 3266489909);
    hs2 = Math.imul(hs2 ^ (hs2 >>> 16), 2246822507);
    hs2 ^= Math.imul(hs1 ^ (hs1 >>> 13), 3266489909);

    return (4294967296 * (2097151 & hs2)) + (hs1 >>> 0);


    /* eslint-enable @typescript-eslint/no-magic-numbers*/
};

export const hashCode = (str: string) => cyrb53(str);

export const noop = () =>
{
    // noop
};

export const secondsToMinutesSeconds = (seconds: number) =>
{
    const MINUTE = 60;
    const RADIX = 10;

    const remainingMinutes = Math.floor(seconds / MINUTE);
    const remainingSeconds = seconds % MINUTE;

    if (remainingMinutes === 0) return `${remainingSeconds}s`;

    return `${remainingMinutes}m ${
        remainingSeconds < RADIX ? "0" : ""
    }${remainingSeconds}s`;
};

export const formatNumber = (number: number) =>
{
    return number.toLocaleString();
};

export const pastSessionDateFormat = (datetime: number) =>
{
    // Render a date from a timestamp to Sept 5th, 2023 format
    const date = new Date(datetime);

    const month = date.toLocaleString("default", { "month": "long" });
    const day = date.getDate();
    const year = date.getFullYear();

    return `${month} ${day}, ${year}`;
};

/**
 * Formats a date to a short format
 * @param {number} datetime The date to format
 * @returns {string} The formatted date
 */
export const shortDateFormat = (datetime: number) =>
{
    const date = new Date(datetime);

    // Render a date from a timestamp to Dec 12, 2023 format
    const month = date.toLocaleString("default", { "month": "short" });
    const day = date.getDate();
    const year = date.getFullYear();

    return `${month} ${day}, ${year}`;
};

/**
 * Format a timestamp to a time in AM/PM format
 * @param datetime
 */
export const formatTime = (datetime: number) =>
{
    const TWELVE = 12;
    const TEN = 10;

    const date = new Date(datetime);

    const hours = date.getHours();
    const minutes = date.getMinutes();

    const period = hours >= TWELVE ? "PM" : "AM";

    const formattedHours = (hours % TWELVE) || TWELVE;

    return `${formattedHours}:${minutes < TEN ? "0" : ""}${minutes} ${period}`;
};

const asNums = (
    nums: unknown[],
    fallback: number,
) => nums.map((num): number => ((typeof num === "number" && !isNaN(num))? num: fallback));

export const min = (
    ...nums: unknown[]
) => Math.min(...asNums(nums, Infinity));

export const max = (
    ...nums: unknown[]
) => Math.max(...asNums(nums, -Infinity));

export const clamp = (
    minimum: unknown,
    value: unknown,
    maximum: unknown,
) => min(max(minimum, value), maximum);

export type PickByType<T, Value> = {
    [P in keyof T as T[P] extends Value | undefined ? P : never]: T[P]
};

export const formatRoomCode = (code: string) =>
{
    const SEGMENT_SIZE = 3;
    const stripped = code.toUpperCase().replace(/[^A-Z]/ug, "");

    return `${stripped.slice(0, SEGMENT_SIZE)}-${stripped.slice(SEGMENT_SIZE)}`;
};
