import WinnerList from "@/components/game/WinnerList";
import type { Type } from "@/realize-shared/model/Event";
import type { GameNodeProps } from "../GameNodeProps";

const GameEnd = ({
    reason,
    round,
    eventRef,
}: GameNodeProps<Type.GAME_END>) =>
{
    if (reason === "completed")
    {
        if (!round) return;

        const players = [...round.players.values()]
            .sort((a, b) => a.score - b.score);

        if (players.length <= 0) return;

        const winner = players[players.length - 1];

        return (
            <WinnerList
                players={players}
                winner={winner}
                ref={eventRef}
            />
        );
    }

    return (
        <div
            className="flex bg-red-500 min-h-[calc(100%_+_2rem)] items-center justify-center flex-col -mb-4"
            ref={eventRef}
        >
            <h1>
                {"Game over!"}
            </h1>
            <h2>
                {`Game ended due to ${reason}`}
            </h2>
        </div>
    );
};

export default GameEnd;
