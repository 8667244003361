const MAX_COLOR = 255;

const SIXTEEN = 16;
const NEGATIVE_TWO = -2;

export const DarkenColor = (color: string, amount: number) =>
{
    return "#" + color
        .replace(/^#/u, "")
        .replace(/../ug, (color) =>
        {
            return ("0"
            + Math.min(
                MAX_COLOR,
                Math.max(
                    0,
                    parseInt(color, SIXTEEN) - amount
                )
            ).toString(SIXTEEN)).slice(NEGATIVE_TWO);
        });
};
