import type { DBHeadlinePack } from "@/realize-shared/model/DBHeadlinePack";
import type { DBIconPack } from "@/realize-shared/model/DBIconPack";
import type { DBThemePack } from "@/realize-shared/model/DBThemePack";
import type { Resource, Text } from "@/realize-shared/model/Types";
import type { UserIcon } from "../realize-shared/model/UserIcon";
import { processTheme, type ProccessThemeSchema } from "@/themes/ProcessTheme";

type Shared<T extends string> = {
    type: T
    slug: Text;
    name: Text;
    blurb: Text,
    locked: boolean;
    price: Text;
    product_id: Text;
};

export type IconPack = Shared<"icon"> & {
    icons: {
        name: Text,
        icons: UserIcon[];
    }[];
};

export type ThemePack = Shared<"theme"> & {
    preview: Resource;
    cdn_path: Resource;
    data: ProccessThemeSchema;
};

export type HeadlinePack = Shared<"headline"> & {
    headlines: Text[];
};

export type ShopItem = IconPack | ThemePack | HeadlinePack;

const processOwnership = (
    {product_id, price}: { product_id: string, price: number },
): { price: string; locked: boolean; } =>
{
    const {store} = CdvPurchase;

    // Check if the pack is owned
    const owned = price <= 0 || store.get(product_id)?.owned;

    // TODO: currency conversion
    const localPrice = "$" + (price - 1/100).toFixed(2);

    if (owned)
    {
        return {
            locked: false,
            price: "0",
        };
    }

    return {
        locked: true,
        price: localPrice,
    };
};

export const toHeadlinePack = (data: DBHeadlinePack): HeadlinePack =>
{
    data = { ...data };

    data.slug = `headline-${data.slug}`;

    if (data.blurb === null)
    {
        data.blurb = `${data.headlines.length} Headlines`;
    }

    const product_id = `headline_pack_${data.slug.replace(/-/ug, "_").replace(/headline_|_pack/ug, "")}`;

    return {
        type: "headline",
        ...data,
        blurb: "",
        product_id,
        ...processOwnership({...data, product_id}),
    };
};

export const toIconPack = (data: DBIconPack): IconPack =>
{
    data = { ...data };

    data.slug = `icon-${data.slug}`;

    if (data.blurb === null)
    {
        const total = data.icons.reduce((c, { icons }) => (c + icons.length), 0);
        const base = data.icons.length;

        data.blurb = `${base} Icons${total-base > 0 ? `, ${total-base} Variants` : ""}`;
    }

    const product_id = `icon_pack_${data.slug.replace(/-/ug, "_").replace(/icon_|_pack/ug, "")}`;

    return {
        type: "icon",
        ...data,
        icons: data.icons.map(({name, icons}) => ({
            name,
            icons: icons.map((hash, i) => ({
                alt: `${name}${i===0?"":` ${i+1}`}`,
                hash,
                path: `https://realize-datastore.nyc3.digitaloceanspaces.com/static/icons/${hash}.webp`
            })),
        })),
        product_id,
        blurb: data.blurb || "default blur",
        ...processOwnership({...data, product_id}),
    };
};

export const toThemePack = async (data: DBThemePack): Promise<ThemePack> =>
{
    data = { ...data };

    data.slug = `theme-${data.slug}`;

    if (data.blurb === null)
    {
        data.blurb = "Theme Preview";
    }

    const cdn_path = `https://realize-datastore.nyc3.digitaloceanspaces.com/static/themes/${data.hash}.xml`;

    const product_id = `theme_pack_${data.slug.replace(/-/ug, "_").replace(/theme_|_pack/ug, "")}`;

    return {
        type: "theme",
        ...data,
        blurb: data.blurb || "default blur",
        cdn_path,
        product_id,
        preview: `https://realize-datastore.nyc3.digitaloceanspaces.com/static/themes/${data.hash}.webp`,
        data: await processTheme(cdn_path),
        ...processOwnership({...data, product_id}),
    };
};
