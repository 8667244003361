import React from "react";
import { twMerge } from "tailwind-merge";

import MagazineAssets from "@/game/magazine/MagazineAssetLoader";
import {
    drawMagazine,
    type MagazineOptions,
} from "@/game/magazine/MagazineGenerator";

const blobToImage = (blob: Blob) =>
{
    const urlCreator = window.URL;

    return urlCreator.createObjectURL(blob);
};

const Magazine = (props: MagazineOptions & {
    className?: string;
    style?: React.CSSProperties;
}) =>
{
    const [image, setImage] = React.useState<string>("");

    const {
        username,
        headline,
        photoPath,
        issueNumber,
        timestamp,
        className,
        isWinner,
        style
    } = props;

    React.useMemo(() =>
    {
        const {
            logo, barCode,
        } = MagazineAssets;

        drawMagazine({
            issueNumber: issueNumber,
            timestamp: timestamp,
            username: username,
            headline: headline,
            photoPath: photoPath,
            isWinner: isWinner,
            logo: logo,
            barCode: barCode,
        }).then((blob) =>
        {
            if (blob)
            {
                const imgURL = blobToImage(blob);

                setImage(imgURL);
            }
        })
            .catch(Logger.error);
    }, [headline, isWinner, issueNumber, photoPath, timestamp, username]);

    return (
        <img
            src={image}
            className={twMerge("w-16 h-auto", className)}
            style={style}
        />
    );
};

export default Magazine;
