import { useGameContext } from "@/game/GameContext";
import type { Player } from "@/game/types/Game";
import { useLayoutEffect, useState } from "react";

const TabButton = ({
    text,
    active,
    onClick,
}: {
    text: string;
    active: boolean;
    onClick?: () => void;
}) =>
{
    return (
        <button
            className={active ? Wind.style({
                color: "text-dark-blue",
                borderBottomColor: "border-b-sharp-red",
                borderBottomWidth: "border-b-2",
                fontWeight: "font-bold",
                fontSize: "text-base",
                lineHeight: "leading-6",
            }).class : Wind.style({
                color: "text-secondary-gray",
                fontWeight: "font-bold",
                fontSize: "text-base",
                lineHeight: "leading-6",
                borderBottomWidth: "border-b-2",
            }).class}
            onClick={onClick}
        >
            {text}
        </button>
    );
};


export default function WebviewPlayerList({
    players,
}: {
    players: Player[];
})
{
    const [maxHeight, setMaxHeight] = useState(0);
    const [currentTab, setCurrentTab] = useState<"players"|"pastRounds">("players");

    const {rules} = useGameContext();

    void players;

    useLayoutEffect(() =>
    {
        const currentSize = document.getElementById("playerListContainer")?.clientHeight;
        const currentTabsSize = document.getElementById("playerListTabs")?.clientHeight;

        if (currentSize && currentTabsSize)
        {
            setMaxHeight(currentSize - currentTabsSize);
        }
    }, [setMaxHeight]);

    window.addEventListener("resize", () =>
    {
        const currentSize = document.getElementById("playerListContainer")?.clientHeight;
        const currentTabsSize = document.getElementById("playerListTabs")?.clientHeight;

        if (currentSize && currentTabsSize)
        {
            setMaxHeight(currentSize - currentTabsSize);
        }
    });

    const getTabContent = () =>
    {
        switch (currentTab)
        {
            case "players":
                return (
                    <div
                        className={Wind.style({
                            display: "flex",
                            flexDirection: "flex-col",
                            gap: "gap-4",
                            padding: "p-4",
                        }).class}
                    >
                        {players.map((player) => (
                            <div
                                key={player.id}
                                className={Wind.style({
                                    display: "flex",
                                    flexDirection: "flex-row",
                                    gap: "gap-2",
                                    alignItems: "items-center",
                                    justifyContent: "justify-between"
                                }).class}
                            >
                                <div
                                    className={Wind.style({
                                        display:"flex",
                                        alignItems: "items-center",
                                        gap: "gap-2",
                                    }).class}
                                >
                                    <img
                                        src={player.avatar.path}
                                        alt={`Avatar of ${player.name}`}
                                        className={Wind.style({
                                            width: "w-6",
                                            height: "h-6",
                                            borderRadius: "rounded-full",
                                        }).class}
                                    />
                                    <span
                                        className={Wind.style({
                                            color: "text-dark-blue",
                                            fontFamily: "font-poppins",
                                            fontWeight: "font-medium",
                                            fontSize: "text-base",
                                            lineHeight: "leading-6",
                                        }).class}
                                    >
                                        {player.name}
                                    </span>
                                </div>
                                <div
                                    className={Wind.style({
                                        display: "flex",
                                        alignItems: "items-baseline",
                                    }).class}
                                >
                                    <span
                                        className={Wind.style({
                                            color: "text-dark-blue",
                                            fontFamily: "font-poppins",
                                            fontWeight: "font-bold",
                                            fontSize: "text-xl",
                                            lineHeight: "leading-6",
                                            marginRight: "mr-1"
                                        }).class}
                                    >
                                        {player.score}
                                    </span>
                                    <span
                                        className={Wind.style({
                                            fontFamily: "font-poppins",
                                            fontWeight: "font-light",
                                            fontSize: "text-xs",
                                            lineHeight: "leading-6",
                                        }).class}
                                    >
                                        {` / ${rules.pointsToWin} pts`}
                                    </span>
                                </div>
                            </div>
                        ))}
                    </div>
                );
            case "pastRounds":
                return (
                    <div
                        className={Wind.style({
                            display: "flex",
                            flexDirection: "flex-col",
                            gap: "gap-4",
                            padding: "p-4",
                        }).class}
                    >
                        Something something past rounds. Something something here?
                    </div>
                );
        }
    };

    return (
        <div
            id="playerListContainer"
            className={Wind.style({
                flexGrow: "grow",
                backgroundColor: "bg-[#F9F5ED]",
                borderRadius: "rounded-lg",
                display: "flex",
                flexDirection: "flex-col",
                overflow: "overflow-hidden",
            }).class}
        >
            <div
                id="playerListTabs"
                className={Wind.style({
                    display: "flex",
                    flexDirection: "flex-row",
                    gap: "gap-6",
                    padding: "p-4",
                    borderBottomColor: "border-b-[#EBE2D5]",
                    borderBottomWidth: "border-b-2",
                }).class}
            >
                <TabButton
                    text="Players"
                    active={currentTab === "players"}
                    onClick={() => void setCurrentTab("players")}
                />
                <TabButton
                    text="Past Rounds"
                    active={currentTab === "pastRounds"}
                    onClick={() => void setCurrentTab("pastRounds")}
                />
            </div>
            <div
                className="flex-grow"
                style={{
                    maxHeight: `${maxHeight}px`,
                }}
            >
                <div className="h-full overflow-y-auto">
                    {maxHeight > 0 && getTabContent()}
                </div>
            </div>
        </div>
    );
}
