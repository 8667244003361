import {ReactComponent as ExpandIcon} from "@/img/chat/expand.svg";
import {ReactComponent as CollapseIcon} from "@/img/chat/collapse.svg";
import type { ThemePack } from "@/game/ShopItem";

const ExpandButton = ({
    toggleExpand,
    expanded,
    theme,
}: {
    toggleExpand: () => void;
    expanded: boolean;
    theme: ThemePack;
}) =>
{
    const {iconColor, iconBackgroundColor} = theme.data.ApplicationSettings.GameContainer.PhotoSubmission.Heading;

    return (
        <button
            className="
                w-8
                h-8
                p-1
                rounded
                absolute
                right-4
                top-1
            "
            style={{
                color: iconColor,
                backgroundColor: iconBackgroundColor,
            }}
            onClick={toggleExpand}
        >
            {expanded ? <CollapseIcon className="w-5 h-5" /> : <ExpandIcon className="w-5 h-5" />}
        </button>
    );
};

export default ExpandButton;
