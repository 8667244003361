import GameSettingsSelection from "@/components/settings/GameSettingsSelection";
import { ReactComponent as IconClock } from "@/img/symbolic/clock.svg";
// import { ReactComponent as IconTrophy } from "@/img/symbolic/trophy.svg";
import { ReactComponent as StartButtonSliver } from "@/img/chat/bottom-start-sliver.svg";
import { ReactComponent as CheckIcon } from "@/img/symbolic/thin-check.svg";
import { ReactComponent as CopyIcon } from "@/img/symbolic/copy.svg";
import { ReactComponent as ShareIcon } from "@/img/symbolic/share.svg";
import { ReactComponent as AudienceIcon } from "@/img/audience-icon.svg";
import * as Time from "@/realize-shared/model/Time";
import { MINIMUM_PLAYERS_FOR_GAME } from "@/data/Config";
import * as PlayerRequests from "@/api/PlayerRequests";
import { useGameContext } from "@/game/GameContext";
import { formatRoomCode } from "@/util/Util";
import React from "react";
import SystemMessage from "@/chat/components/SystemMessage";
import * as Clipboard from "@/util/Clipboard";
import type { Type } from "@/realize-shared/model/Event";
import type { Event } from "@/game/events/ProcessedEvent";
import { EventState } from "@/game/events/ProcessedEventMap";
import type { ThemePack } from "@/game/ShopItem";
import { DarkenColor } from "@/util/ColorControls";
import { PLATFORM } from "@/data/Environment";
import { Share } from "@capacitor/share";
import type { GameNodeProps } from "../GameNodeProps";
import type { Settings } from "@/realize-shared/model/Types";

const GAME_TIME_VALUES: number[] = (() =>
{
    try
    {
        const times = (JSON.parse(import.meta.env.VITE_GAME_TIME_VALUES as string) as number[])
            .filter((n): n is number =>
            {
                AssertTrue(typeof n === "number");
                n = Math.round(n);
                AssertTrue(0 < n && n < Number.MAX_SAFE_INTEGER);

                return true;
            })
            .sort((a, b) => a - b);

        AssertTrue(times.length >= 0);

        return times;
    }
    catch (e)
    {
        Logger.error(e);
        Logger.error("Did you remember to set VITE_GAME_TIME_VALUES?");

        return [10];
    }
})();

// const POINTS_TO_WIN_VALUES: number[] = (() =>
// {
//     try
//     {
//         const points = (JSON.parse(import.meta.env.VITE_POINTS_TO_WIN as string) as number[])
//             .filter((n): n is number =>
//             {
//                 AssertTrue(typeof n === "number");
//                 n = Math.round(n);
//                 AssertTrue(0 < n && n < Number.MAX_SAFE_INTEGER);

//                 return true;
//             })
//             .sort((a, b) => a - b);

//         AssertTrue(points.length >= 0);

//         return points;
//     }
//     catch (e)
//     {
//         Logger.error(e);
//         Logger.error("Did you remember to set VITE_POINTS_TO_WIN?");

//         return [10];
//     }
// })();

const LobbyControls = ({
    state,
    theme,
    eventRef,
}: GameNodeProps<Type.GAME_START>) =>
{
    const {
        currentPlayers,
        room,
        host,
    } = useGameContext();

    const [isGameStarted, setIsGameStarted] = React.useState(false);

    if (state === EventState.STATIC) return null;

    const startGame = () =>
    {
        Logger.log("Start Game");
        setIsGameStarted(true);

        PlayerRequests.sendStartGameRequest(room.id)
            .then((data) => void Logger.log("Game Started", data))
            .catch((error) =>
            {
                Logger.error("Failed to start game", error);
                setIsGameStarted(false);
            });
    };

    const disabled = currentPlayers.size < MINIMUM_PLAYERS_FOR_GAME || isGameStarted;

    const {LobbyControls} = theme.data.ApplicationSettings.TopSection;

    return (
        <div
            className="
                flex
                -mt-4
                -top-4
                sticky
                z-[1]
                w-full
                flex-col
            "
            ref={eventRef}
        >
            {state === EventState.ACTIVE
                ? (
                    <button
                        className="
                        w-full
                        mb-3
                        flex
                        items-center
                        justify-center
                        gap-2
                        relative
                        font-semibold
                        font-poppins
                        text-base
                        py-2
                    "
                        disabled={disabled}
                        onClick={startGame}
                        style={{
                            backgroundColor: disabled
                                ? LobbyControls.StartButton.disabledBackgroundColor
                                : LobbyControls.StartButton.backgroundColor,
                            color: disabled
                                ? LobbyControls.StartButton.disabledTextColor
                                : LobbyControls.StartButton.textColor,
                        }}
                    >
                        <CheckIcon />
                        Start Game
                        <StartButtonSliver
                            className={`
                                absolute
                                bottom-[0.5px]
                                translate-y-full
                                w-full
                            `}
                            style={{
                                color: disabled
                                    ? LobbyControls.StartButton.disabledBackgroundColor
                                    : LobbyControls.StartButton.backgroundColor,
                            }}
                        />
                    </button>)
                : (
                    <div
                        className="
                            w-full
                            mb-3
                            flex
                            items-center
                            justify-center
                            gap-2
                            relative
                            font-poppins
                            text-base
                            py-2
                        "
                        style={{
                            backgroundColor: LobbyControls.WaitStatus.backgroundColor,
                            color: LobbyControls.WaitStatus.textColor,
                        }}
                    >
                        <b>
                            {host.name}
                        </b>
                        is setting up the room
                        <StartButtonSliver
                            className={`
                                absolute
                                bottom-0
                                translate-y-full
                            `}
                            style={{
                                color: LobbyControls.WaitStatus.backgroundColor,
                            }}
                        />
                    </div>
                )}
            <SystemMessage
                theme={theme}
                message={`Hang Tight! *${host.name}* is setting up the room`}
            />
        </div>
    );
};

export const LowerLobbyControls = ({
    event,
    theme,
    hideRoomCode,
}: { event: Event, theme: ThemePack, hideRoomCode?: boolean }) =>
{
    const {
        rules,
        room,
        host,
        self
    } = useGameContext();

    const {state} = event;

    const formattedRoomCode = formatRoomCode(room.id);
    const {LobbyControls} = theme.data.ApplicationSettings.TopSection;

    const getButtonText = (visibility: "public" | "private" | "redacted") =>
    {
        switch (visibility)
        {
            case "public":
                return "Public";
            case "private":
                return "Private";
            case "redacted":
                return "Photos Only";
        }
    };

    const nextState = (visibility: "public" | "private" | "redacted") =>
    {
        switch (visibility)
        {
            case "public":
                return "redacted";
            case "private":
                return "public";
            case "redacted":
                return "private";
        }
    };

    if (state === EventState.STICKY || state === EventState.ACTIVE)
    {
        return (

            <div
                className="
                text-opacity-[0.72]
                w-full
            "
                style={{
                    backgroundColor: LobbyControls.backgroundColor,
                    color: LobbyControls.textColor,
                }}
            >
                <div
                    className="
                    flex
                    gap-3
                    items-center
                    py-2
                    pl-3
                    border-b
                    overflow-x-auto
                    whitespace-nowrap
                "

                    style={{
                        borderBottomColor: DarkenColor(LobbyControls.backgroundColor, 10),
                    }}
                >
                    <span
                        className="
                        font-poppins
                        text-base
                    "
                    >
                        Game Settings
                    </span>
                    <GameSettingsSelection
                        locked={state !== EventState.ACTIVE}
                        icon={<IconClock className="w-5 h-5" />}
                        values={GAME_TIME_VALUES}
                        value={rules.roundLength / Time.MS_IN_M}
                        title="Round Length"
                        prefix=""
                        suffix="Minutes"
                        onSubmit={(value) =>
                        {
                            if (typeof value !== "undefined" && typeof value === "number")
                            {
                                const updated: Settings = {
                                    ...rules,
                                    "roundLength": value * Time.MS_IN_M,
                                };

                                PlayerRequests.sendRuleChanges(room.id, updated).catch(Logger.error);
                                room.data.write({rules: updated}, true);
                            }

                            document.body.classList.remove("pop-over");
                        }}
                    />
                    <button
                        className={Wind.style({
                            backgroundColor: host.id === self.id ? "bg-poppy-yellow" : "bg-[#D7DEE7]",
                            color: "text-dark-blue",
                            paddingX: "px-2",
                            paddingY: "py-1",
                            borderRadius: "rounded-lg",
                            fontWeight: "font-bold",
                            fontFamily: "font-poppins",
                            display: "flex",
                            alignItems: "items-center",
                            gap: "gap-2"
                        }).class}
                        onClick={() =>
                        {
                            if (host.id !== self.id) return;

                            const newVisibility = nextState(rules.visibility);

                            const updated: Settings = {
                                ...rules,
                                visibility: newVisibility,
                            };

                            PlayerRequests.sendRuleChanges(room.id, updated).catch(Logger.error);
                            room.data.write({rules: updated}, true);
                        }}
                    >
                        <AudienceIcon />
                        <span>
                            {getButtonText(rules.visibility)}
                        </span>
                    </button>
                    {/* <GameSettingsSelection
                        locked={state !== EventState.ACTIVE}
                        icon={<IconTrophy className="w-5 h-5" />}
                        values={POINTS_TO_WIN_VALUES}
                        value={rules.pointsToWin}
                        title="Score Limit"
                        prefix=""
                        suffix="Points to Win"
                        onSubmit={(value) =>
                        {
                            if (typeof value !== "undefined")
                            {
                                const updated = {
                                    ...rules,
                                    "pointsToWin": value,
                                };

                                PlayerRequests.sendRuleChanges(room.id, updated).catch(Logger.error);
                                room.data.write({rules: updated}, true);
                            }

                            document.body.classList.remove("pop-over");
                        }}
                    /> */}
                </div>
                {!hideRoomCode && (
                    <div
                        className="
                            px-3
                            py-2
                            justify-between
                            items-center
                            flex
                            border-b
                        "
                        style={{
                            borderBottomColor: DarkenColor(LobbyControls.backgroundColor, 10),
                        }}
                    >
                        <span
                            className="
                                font-poppins
                                text-base
                            "
                        >
                        Room Code
                        </span>
                        <div
                            className="
                                font-bold
                                text-poppy-yellow
                                flex
                                items-center
                                gap-3
                            "
                            onClick={() =>
                            {
                                if (PLATFORM === "web")
                                {
                                    Clipboard.copy(formattedRoomCode).catch(Logger.warn);

                                    return;
                                }

                                Share.canShare().then((canShare) =>
                                {
                                    if (canShare.value)
                                    {
                                        Share.share({
                                            title: "Join my Realize Room",
                                            text: `Join my Realize Room: ${formattedRoomCode}`,
                                        }).catch(Logger.error);
                                    }
                                    else
                                    {
                                        Clipboard.copy(formattedRoomCode).catch(Logger.warn);
                                    }
                                })
                                    .catch(Logger.error);
                            }}
                        >
                            {formattedRoomCode}
                            {PLATFORM === "web" ? <CopyIcon className="w-5 h-5" /> : <ShareIcon className="w-5 h-5" />}
                        </div>
                    </div>
                )}
            </div>
        );
    }

    return null;
};

export default LobbyControls;
