import * as HumanRequests from "@/api/HumanRequests";
import type { Room, Rooms } from "@/game/types/Game";
import { CHAT_POLL_TIME_INITIAL, CHAT_POLL_TIME_SCALE, CHAT_POLL_TIME_MAX } from "@/data/Config";
import { PollingQueue } from "@/realize-shared/PollingQueue";
import type { GetRoomEventsResponse } from "@/realize-shared/model/RequestTypes";
import type { HumanID, RoomID } from "@/realize-shared/model/Types";
import unFlat from "@/realize-shared/unFlat";
import type { StateTuple, SetState } from "../types/ReactTypes";
import EventManager from "./EventManager";
import * as Pushy from "@/api/Pushy";

let rooms: Rooms = new Map();
let setRooms: SetState<Rooms> = () => void 0;
let queue: PollingQueue | undefined;
let humanID = "";

const buildQueue = () => new PollingQueue(
    async () => void await checkAllRoomsForNewEvents(),
    CHAT_POLL_TIME_INITIAL,
    CHAT_POLL_TIME_SCALE,
    CHAT_POLL_TIME_MAX,
    true, // poll immediately
    true, // stop polling on error
);

export const init = () =>
{
    if (queue) return;

    queue = buildQueue();
};

export const setHumanID = (hid: HumanID) =>
{
    humanID = hid;
};

const error = () => new Error("Not yet initialized. Call update() at least once");

export const update = (...state: StateTuple<Rooms>) =>
{
    if (!queue) throw error();

    [rooms, setRooms] = state;

    queue.start();

    return queue.stop;
};

export const reset = () =>
{
    if (!queue) throw error();

    queue.reset();
};

const fillEvents = async (
    room: Room,
    data: GetRoomEventsResponse,
) =>
{
    if (data.events.length === 0) return false;

    await room.data.update(data);

    return true;
};

export const buildRoom = (roomID: string, humanID: string, interactive: boolean) =>
{
    const room: Room = {
        id: roomID,
        data: null as unknown as EventManager,
    };

    const manager = new EventManager(room, roomID, humanID, interactive);

    room.data = manager;

    return room;
};

const addRoom = async (
    roomID: RoomID,
    data: GetRoomEventsResponse,
) =>
{
    AssertTrue(humanID !== "", "HumanID not set");

    const room = buildRoom(roomID, humanID, true);

    Pushy.subscribe({ roomID }).catch(Logger.error);

    rooms.set(roomID, room);

    await fillEvents(room, data);
};

const removeRoom = (
    roomID: RoomID,
): void =>
{
    const room = rooms.get(roomID);

    if (!room) return;

    room.data.dispose();

    rooms.delete(roomID);

    Pushy.unsubscribe({ roomID }).catch(Logger.error);
};

const checkAllRoomsForNewEvents = async () =>
{
    if (!humanID) return;

    const tempRooms = new Map(
        [...rooms.entries()]
            .filter(([_id, room]) => room.data.alive)
    );

    const res = await HumanRequests.getRoomData(
        [...tempRooms.values()].map(room => room.data.state),
    );

    const model: [RoomID, GetRoomEventsResponse] = ["", {events: [], hasMore: false}];
    const data = unFlat(model, res.data);

    let hadARoomListUpdate = false;
    let hadARoomUpdate = false;

    for (const [roomID, roomData] of data)
    {
        const room = tempRooms.get(roomID);

        if (room)
        {
            const newEvents = await fillEvents(room, roomData);

            hadARoomUpdate ||= newEvents;
        }
        else
        {
            await addRoom(roomID, roomData);

            hadARoomListUpdate = true;
        }

        tempRooms.delete(roomID);
    }

    for (const room of tempRooms.keys())
    {
        removeRoom(room);

        hadARoomListUpdate = true;
    }

    if (hadARoomListUpdate)
    {
        setRooms(new Map(rooms));
        hadARoomUpdate = true;
    }

    Logger.debug("check", hadARoomListUpdate, hadARoomUpdate);

    return hadARoomUpdate;
};
