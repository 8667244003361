import {
    DEV, PROD,
} from "@/data/Environment";

/**
 * Levels as defined by google dev api
 * https://developer.chrome.com/docs/devtools/console/api/
 *
 *         Silent   0
 * assert  Error    1
 * error   Error
 * warn    Warning  2
 * count   Info     3
 * dir     Info
 * dirxml  Info
 * info    Info
 * log     Info
 * table   Info
 * timeEnd Info
 * trace   Info
 * debug   Verbose 4
 */

enum Level
{
    SILENT = 0,
    ERROR = 1,
    WARNING = 2,
    INFO = 3,
    VERBOSE = 4,
}

const DEV_LOG_LEVEL = Level.VERBOSE;
const PRODUCTION_LOG_LEVEL = Level.INFO;

const noop = () =>
{
    // noop
};

export class Logger
{
    /* eslint-disable @typescript-eslint/no-unnecessary-condition */
    /* eslint-disable no-console */


    static #instance?: Logger;

    private constructor ()
    {
        // Private constructor, singleton
    }

    public static get instance (): Logger
    {
        if (!Logger.#instance) Logger.#instance = new Logger();

        return Logger.#instance;
    }

    // Verbose

    public get debug ()
    {
        if (DEV && DEV_LOG_LEVEL < Level.VERBOSE) return noop;
        if (PROD && PRODUCTION_LOG_LEVEL < Level.VERBOSE) return noop;

        return console.debug.bind(console);
    }

    // Info

    public get trace ()
    {
        if (DEV && DEV_LOG_LEVEL < Level.INFO) return noop;
        if (PROD && PRODUCTION_LOG_LEVEL < Level.INFO) return noop;

        return console.trace.bind(console);
    }

    public get timeEnd ()
    {
        if (DEV && DEV_LOG_LEVEL < Level.INFO) return noop;
        if (PROD && PRODUCTION_LOG_LEVEL < Level.INFO) return noop;

        return console.timeEnd.bind(console);
    }

    public get table ()
    {
        if (DEV && DEV_LOG_LEVEL < Level.INFO) return noop;
        if (PROD && PRODUCTION_LOG_LEVEL < Level.INFO) return noop;

        return console.table.bind(console);
    }

    public get log ()
    {
        if (DEV && DEV_LOG_LEVEL < Level.INFO) return noop;
        if (PROD && PRODUCTION_LOG_LEVEL < Level.INFO) return noop;

        return console.log.bind(console);
    }

    public get info ()
    {
        if (DEV && DEV_LOG_LEVEL < Level.INFO) return noop;
        if (PROD && PRODUCTION_LOG_LEVEL < Level.INFO) return noop;

        return console.info.bind(console);
    }

    public get dirxml ()
    {
        if (DEV && DEV_LOG_LEVEL < Level.INFO) return noop;
        if (PROD && PRODUCTION_LOG_LEVEL < Level.INFO) return noop;

        return console.dirxml.bind(console);
    }

    public get dir ()
    {
        if (DEV && DEV_LOG_LEVEL < Level.INFO) return noop;
        if (PROD && PRODUCTION_LOG_LEVEL < Level.INFO) return noop;

        return console.dir.bind(console);
    }

    public get count ()
    {
        if (DEV && DEV_LOG_LEVEL < Level.INFO) return noop;
        if (PROD && PRODUCTION_LOG_LEVEL < Level.INFO) return noop;

        return console.count.bind(console);
    }

    // Warning

    public get warn ()
    {
        if (DEV && DEV_LOG_LEVEL < Level.WARNING) return noop;
        if (PROD && PRODUCTION_LOG_LEVEL < Level.WARNING) return noop;

        return console.warn.bind(console);
    }

    // Error

    public get error ()
    {
        if (DEV && DEV_LOG_LEVEL < Level.ERROR) return noop;
        if (PROD && PRODUCTION_LOG_LEVEL < Level.ERROR) return noop;

        return console.error.bind(console);
    }

    public get assert ()
    {
        if (DEV && DEV_LOG_LEVEL < Level.ERROR) return noop;
        if (PROD && PRODUCTION_LOG_LEVEL < Level.ERROR) return noop;

        return console.assert.bind(console);
    }

    // Other

    public get clear ()
    {
        return console.clear.bind(console);
    }

    public get countReset ()
    {
        return console.countReset.bind(console);
    }

    public get group ()
    {
        return console.group.bind(console);
    }

    public get groupCollapsed ()
    {
        return console.groupCollapsed.bind(console);
    }

    public get groupEnd ()
    {
        return console.groupEnd.bind(console);
    }

    public get time ()
    {
        return console.time.bind(console);
    }

    public get timeLog ()
    {
        return console.timeLog.bind(console);
    }

    public get timeStamp ()
    {
        return console.timeStamp.bind(console);
    }

    /* eslint-enable @typescript-eslint/no-unnecessary-condition */
    /* eslint-enable no-console */
}

export default Logger.instance;
