import { twMerge } from "tailwind-merge";

import type { Resource } from "@/realize-shared/model/Types";

const ChatPolaroid = ({
    photo,
    className
}: {
    className?: string;
    photo: Resource;
}) =>
{
    return (
        <div
            className={twMerge(`
                bg-white
                p-2
                overflow-clip
                w-[200px]
                mt-8
                mx-auto
                -rotate-3
                relative
                flex
                flex-col
                drop-shadow-xl
            `, className)}
        >

            <img
                className="w-full max-auto object-cover object-center aspect-[291/375] bg-white"
                src={photo}
            />
            <div className="items-center justify-center h-2 block" />
        </div>
    );
};

export default ChatPolaroid;
