import {
    createTools, type Tailwindest,
} from "tailwindest";
import { twMerge } from "tailwind-merge";
import type { PickByType } from "@/util/Util";

export type WindTypes = Tailwindest<
    {
        color:
            | "dark"
            | "dark-1"
            | "dark-2"
            | "dark-3"
            | "dark-4"
            | "dark-5"
            | "light"
            | "light-1"
            | "light-2"
            | "light-3"
            | "light-4"
            | "light-5"
            | "accent"
            | "sharp-red"
            | "poppy-yellow"
            | "mint-green"
            | "light-blue"
            | "dark-blue"
            | "secondary-gray"
            | "parchment";
        screens:
            | "me"
            | "se"
            | "le"
            | "xe";
    },
    {
        fontFamily:
            | "sans"
            | "poppins"
            | "monoton"
            | "playfair"
            | "hand"
            | "marker",
    }
>;

type BasicWindTypes = PickByType<WindTypes, string>;

export type WindClasses = Exclude<BasicWindTypes[keyof BasicWindTypes], undefined>;

const tools = createTools<WindTypes>();

const classlist = () =>
{
    const data = {
        class: "",
        wind (...classes: WindClasses[])
        {
            return join(classes);
        },
        misc (...classes: string[])
        {
            return join(classes);
        },
    };

    const join = (classes: string[]) =>
    {
        data.class = twMerge(data.class, classes.join(" "));

        return data;
    };

    return data;
};

const Tailwind = {
    ...tools,
    list: classlist,
} as const;

export default Tailwind;
