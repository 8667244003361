import React from "react";
import {ReactComponent as CloseIcon} from "@/img/symbolic/close.svg";
import {ReactComponent as TopSliver} from "@/img/chat/photos-in-sliver-top.svg";
import {ReactComponent as BottomSliver} from "@/img/chat/photos-in-sliver-bottom.svg";
import YellowButtonBG from "@/img/layout/jagged-button-poppy.svg";
import RedButtonBG from "@/img/layout/jagged-button-red.svg";
import type { Type } from "@/realize-shared/model/Event";
import { useGameContext } from "@/game/GameContext";
import * as PlayerRequests from "@/api/PlayerRequests";
import { EventState } from "@/game/events/ProcessedEventMap";
import ExpandButton from "@/chat/components/ExpandButton";
import { twMerge } from "tailwind-merge";
import type { GameNodeProps } from "../GameNodeProps";

const PhotosInPolaroid = ({
    expanded,
    photoURL,
    onClick,
    className,
}: {
    expanded?: boolean;
    photoURL?: string;
    onClick?: () => void;
    className?: string;
}) =>
{
    return (
        <div
            className={
                twMerge(`
                    bg-white
                    px-1
                    py-1
                    pb-3
                    aspect-[85/102]
                    overflow-clip
                    ${expanded ? "w-full" : "w-20 flex-[0_0_5rem]"}
                `, className)
            }
            onClick={onClick}
        >
            <img
                src={photoURL}
                className="bg-gray-500 w-full h-full object-cover"
            />
        </div>
    );
};

const PhotosAreIn = ({
    submissions,
    state,
    theme,
    eventRef,
}: GameNodeProps<Type.VOTE_START>) =>
{
    const { room } = useGameContext();

    const [expanded, setExpanded] = React.useState(false);

    const [viewed, setViewed] = React.useState(-1);

    const [hasStartedSelection, setHasStartedSelection] = React.useState(false);

    const startSelection = () =>
    {
        setHasStartedSelection(true);
    };

    const confirmSelection = () =>
    {
        setHasStartedSelection(false);
        setViewed(-1);

        void PlayerRequests.selectWinner(room.id, submissions[viewed].player);
    };

    const toggleExpand = () =>
    {
        setExpanded(state => !state);
    };

    const canVote = state === EventState.ACTIVE;
    const sticky = state !== EventState.STATIC;

    const { PhotoSubmission } = theme.data.ApplicationSettings.GameContainer;

    if (viewed > -1)
    {
        return (
            <div
                className="fixed z-[100] bg-black inset-0 pt-[--safe-area-top] pb-[--safe-area-bottom]"
                ref={eventRef}
            >
                <button
                    className="
                        w-8
                        h-8
                        p-1
                        bg-[rgba(255,255,255,0.20)]
                        rounded
                        ml-4
                        flex
                        items-center
                        justify-center
                        absolute
                    "
                    onClick={() => void setViewed(-1)}
                >
                    <CloseIcon className="w-5 h-5" />
                </button>
                <img
                    src={submissions[viewed].photo}
                    className="w-full h-full object-contain"
                    onClick={() => void setViewed(-1)}
                />
                {canVote && (
                    <button
                        className={`
                            absolute
                            font-semibold
                            font-poppins
                            text-xl
                            leading-[130%]
                            w-auto
                            mb-[unset]
                            aspect-[224/72]
                            h-[72px]
                            pb-2
                            right-0
                            bottom-[--safe-area-bottom]
                            ${hasStartedSelection? "text-dark-blue": "text-white"}
                    `}
                        style={{
                            "backgroundImage": hasStartedSelection
                                ? `url(${YellowButtonBG})`
                                : `url(${RedButtonBG})`,
                        }}
                        onClick={hasStartedSelection? confirmSelection: startSelection}
                    >
                        {hasStartedSelection ? "Confirm Winner?" : "Choose Winner"}
                    </button>
                )}
            </div>
        );
    }

    return (
        <div
            className={`mt-2 mb-2 ${sticky && "sticky drop-shadow-[0px_0px_4px_#000] z-[2]"} -top-5 -bottom-3`}
            ref={eventRef}
        >
            <TopSliver
                className="w-full"
                style={{
                    color: PhotoSubmission.Heading.backgroundColor,
                    transform: "translateY(0.5px)"
                }}
            />
            <div
                className="flex items-center justify-center relative w-full"
                style={{
                    backgroundColor: PhotoSubmission.Heading.backgroundColor,
                }}
            >
                {" "}
                <span
                    className="
                        font-poppins
                        font-semibold
                        text-base
                        py-2
                        px-4
                        relative
                        w-full
                        text-center
                    "
                    style={{
                        color: PhotoSubmission.Heading.textColor,
                    }}
                >
                    Photos are in!
                    <ExpandButton
                        toggleExpand={toggleExpand}
                        expanded={expanded}
                        theme={theme}
                    />
                </span>
            </div>
            <BottomSliver
                className="w-full"
                style={{
                    color: PhotoSubmission.Heading.backgroundColor,
                    transform: "translateY(-0.5px)"
                }}
            />
            <div
                className={`
                    -my-2
                    ${expanded ? `
                    grid grid-cols-3
                    `
            : `
                    flex-nowrap
                    overflow-x-auto
                    `}
                    py-3
                    px-4
                    flex
                    gap-2
                `}
                style={{
                    backgroundColor: PhotoSubmission.Photos.backgroundColor
                }}
            >
                {submissions.map((submission, index) =>
                {
                    return (
                        <PhotosInPolaroid
                            key={index}
                            expanded={expanded}
                            photoURL={submission.photo}
                            onClick={() => void setViewed(index)}

                            className="shadow-[0px_2px_8px_0px] shadow-[rgba(0,0,0,0.4)]"
                        />
                    );
                })}
            </div>
            <BottomSliver
                className="w-full translate-y-full"
                style={{
                    color: PhotoSubmission.Photos.backgroundColor,
                }}
            />
        </div>
    );
};

export default PhotosAreIn;
