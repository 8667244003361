import {ReactComponent as JaggedButtonSVG} from "@/img/layout/raw-jagged-button.svg";
import type { WindTypes } from "@/util/Tailwind";
import { twMerge } from "tailwind-merge";

type ThemeName = "default" | "deep-gray" | "sharp-red" | "poppy-yellow" | "parchment" | "dull-green";
type Theme = Record<"button"|"text", WindTypes["color"]>;

const THEMES: Record<ThemeName, Theme> = {
    "default": {
        button: "text-black",
        text: "text-white",
    },
    "deep-gray": {
        button: "text-[#1D2735]",
        text: "text-white",
    },
    "sharp-red": {
        button: "text-sharp-red",
        text: "text-white",
    },
    "poppy-yellow": {
        button: "text-poppy-yellow",
        text: "text-dark-blue",
    },
    "parchment": {
        button: "text-parchment",
        text: "text-dark-blue",
    },
    "dull-green": {
        button: "text-[#689962]",
        text: "text-white",
    },
};

export const DialogButton = ({
    onClick,
    text,
    color,
    disabled,
    className,
}: {
    onClick: () => void;
    text: string|React.ReactNode;
    color?: Theme | ThemeName;
    disabled?: boolean;
    className?: string;
}) =>
{
    color = (typeof color === "object")? color: THEMES[color ?? "default"];

    return (
        <button
            onClick={onClick}
            disabled={disabled}
            className={twMerge(`
                w-[7.5rem]
                h-10
                relative
                ${color.text}
                transition-colors
                font-poppins
                font-semibold
            `, className)}
        >
            <JaggedButtonSVG className={`${color.button} transition-colors w-full h-full`} />
            <span className="absolute inset-0 flex items-center justify-center">
                {text}
            </span>
        </button>
    );
};
