import { useGameContext } from "@/game/GameContext";
import { useMemo } from "react";
import WebviewPlayerList from "./PlayerList";
import MagazinePreview from "./MagazinePreview";
import { Type } from "@/realize-shared/model/Event";
import type { Player, Round } from "@/game/types/Game";
import type { Resource } from "@/realize-shared/model/Types";


const SideBar = () =>
{
    const { round, currentPlayers, events } = useGameContext();

    const players = useMemo(() =>
    {
        if (!round) return [...currentPlayers.values()];

        return [...round.players.values()];
    }, [round, currentPlayers]);

    // Get last result event

    const lastResult = useMemo(() =>
    {
        if (!round) return null;

        return events.findLast((event) => event.type === Type.VOTE_RESULT) as {
            round: Round;
            winner: Player;
            photo: Resource;
            time: number;
        };
    }, [events, round]);


    return (
        <div
            className={Wind.style({
                display: "flex",
                flex: "flex-[0_0_19.75rem]",
                flexDirection: "flex-col",
                gap: "gap-3"
            }).class}
        >
            {players.length > 0 && lastResult && (
                <MagazinePreview
                    key={`${round?.number}-${players.length}`}
                    winner={lastResult.winner}
                    photo={lastResult.photo}
                    headline={lastResult.round.headline ?? ""}
                    timestamp={lastResult.time}
                />
            )}
            <WebviewPlayerList players={players} />
            <div
                className={Wind.style({
                    backgroundColor: "bg-pink-500",
                    flexGrow: "grow-0",
                    flexShrink: "shrink-0",
                    display: "flex",
                    justifyContent: "justify-center",
                    alignItems: "items-center",
                    color: "text-white",
                }).class}
                style={{
                    aspectRatio: "300 / 250",
                }}
            >
                <div>
                    <script
                        async
                        // eslint-disable-next-line @stylistic/max-len
                        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8823077351295808"
                        crossOrigin="anonymous"
                    >
                    </script>
                    <ins
                        className="adsbygoogle"
                        style={{
                            display: "inline-block",
                            width: "316px",
                            height: "263px",
                        }}
                        data-ad-client="ca-pub-8823077351295808"
                        data-ad-slot="3811673646"
                    >
                    </ins>
                    <script>
                        (adsbygoogle = window.adsbygoogle || []).push(
                        {}
                        );
                    </script>
                </div>
            </div>
        </div>
    );
};

export default SideBar;
