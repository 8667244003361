import * as Requests from "./Requests";
import type { DBIconPack } from "@/realize-shared/model/DBIconPack";
import type { DBThemePack } from "@/realize-shared/model/DBThemePack";

export const dataset = async () =>
    await Requests.get<{ packs: string[] }>(false, "app/dataset");

export const getIconPacks = async () =>
    await Requests.get<{ packs: DBIconPack[] }>(false, "app/icons");

export const getThemePacks = async () =>
    await Requests.get<{ packs: DBThemePack[] }>(false, "app/themes");

