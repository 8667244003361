/**
 * Checks if a string has correctly balanced brackets
 *
 * @param expression The string to check
 * @param open The opening bracket
 * @param close The closing bracket
 * @returns true iff the brackets are balanced
 * @throws if open/close are the same or not exactly one char long
 */
export const isBalanced = (
    expression: string,
    open = "(",
    close = ")",
) =>
{
    if (open.length !== 1) throw new Error(`Invalid opening brace: '${open}'`);
    if (close.length !== 1) throw new Error(`Invalid closing brace: '${close}'`);
    if (open === close) throw new Error("Opening and closing braces cannot be the same");

    let count = 0;

    for (const char of expression)
    {
        count += (char === open)? 1: (char === close)? -1: 0;

        if (count < 0) return false;
    }

    return count === 0;
};

// general replacer builder
export const sanitizer = (
    patterns: RegExp[],
    preprocess = (str: string) => str,
    postprocess = (str: string) => str,
) => (text: string) =>
{
    text = preprocess(text).trimStart();

    text = Array.from(text)
        .filter((char) =>
        {
            for (const pattern of patterns)
            {
                if (pattern.test(char)) return true;
            }

            return false;
        })
        .join("")
        .replaceAll(/\s+/gu, " ");

    text = postprocess(text);

    return text;
};

// eslint-disable-next-line require-unicode-regexp
const emojiClass = new RegExp([
    "\\u00a9",
    "\\u00ae",
    "[\\u2000-\\u3300]",
    "\\ud83c[\\ud000-\\udfff]",
    "\\ud83d[\\ud000-\\udfff]",
    "\\ud83e[\\ud000-\\udfff]",
].join("|"), "");

const cLetter = "\\p{L}";
const cAccent = "\\p{M}";
const cSymbol = "\\p{S}";
const cNumber = "\\p{N}";
const cPunctuation = "\\p{P}";

const basicClass = new RegExp(`[ \\-_${cLetter}${cAccent}${cNumber}]`, "u");
const extraClass = new RegExp(`[${cSymbol}${cPunctuation}]`, "u");

/**
 * Removes most /weird/ chars from input string
 *
 * @param text The input string to process
 * @return the filtered string
 */
export const sanitizeTextBlock = sanitizer([basicClass, emojiClass, extraClass]);

/**
 * Remove most symbols that might be interpreted as code from input string
 *
 * Remove anything that is not ' ', '-', '_', an emoji, a letter of some sort
 * that implies removing most symbols that might be interpreted as code
 *
 * @param text The input string to process
 * @return the filtered string
 */
export const sanitizeName = sanitizer([basicClass, emojiClass]);

// remove anything that is not A-Z or '-'
const AZDashPattern = /[A-Z\\-]/u;

/**
 * Converts input string to uppercase and then removes anything that is not A-Z or '-'
 *
 * @param text The input string to process
 * @return the filtered string
 */
export const sanitizeAZDash = sanitizer([AZDashPattern], (str) => str.toUpperCase());

// only allow chars such as '12*8/93+(8-4)%2'
const JSMathExpressionPattern = /[\d()+\-*/%]/u;

// convert '123(456)789' into '123*(456)*789'
const replaceImplicitBracketMultiply = (str: string) =>
{
    if (!str) throw new Error("Invalid expression");

    const onlyDigits = str.replaceAll(/\D/gu, "");

    if (!onlyDigits) throw new Error("Empty expression");

    if (!isBalanced(str)) throw new Error("Expression not balanced");

    return str.replaceAll(
        /(?<digit>\d)(?<opening>\()/gu,
        "$<digit>*$<opening>",
    ).replaceAll(
        /(?<closing>\))(?<digit>\d)/gu,
        "$<closing>*$<digit>",
    );
};

/**
 * Filters a mathematical expression to only contain brackets, basic operators, and digits
 *
 * @param text The input string to process
 * @return The filtered string
 * @throws If the parser detects any invalid expression
 */
export const sanitizeJSMathExpression = sanitizer(
    [JSMathExpressionPattern],
    void 0, // don't preprocess
    replaceImplicitBracketMultiply,
);
