import { useGameContext } from "@/game/GameContext";
import type { Type } from "@/realize-shared/model/Event";
import { ReactComponent as RulesBottomSliver } from "@/img/chat/bottom-rules-sliver.svg";
import { ReactComponent as StartButtonSliver } from "@/img/chat/bottom-start-sliver.svg";
import { ReactComponent as PenIcon } from "@/img/chat/pen-icon.svg";
import SystemMessage from "@/chat/components/SystemMessage";
import { GameUIState } from "@/game/GameUIState";
import { EventState } from "@/game/events/ProcessedEventMap";
import type { ThemePack } from "@/game/ShopItem";
import type { GameNodeProps } from "../GameNodeProps";

const CreateHeadlineControls = ({theme}: { theme: ThemePack }) =>
{
    const { room } = useGameContext();

    const {CreateHeadlineEventEditor} = theme.data.ApplicationSettings.GameContainer;

    return (
        <div
            className="
                flex
                -top-4
                sticky
                -bottom-7
                z-[1]
                w-full
                flex-col
                drop-shadow-[0px_0px_4px_#000]
            "
        >
            <div
                className="
                    px-2
                    pt-4
                    pb-3
                    flex
                    justify-center
                    items-center
                    gap-3
                    w-full
                    relative
                    text-base
                    leading-8
                "
                style={{
                    backgroundColor: CreateHeadlineEventEditor.Heading.backgroundColor,
                    color: CreateHeadlineEventEditor.Heading.textColor,
                }}
            >
                <span>
Hey
                    <b style={{color: CreateHeadlineEventEditor.Heading.accentColor}}> Chief</b>
! It&apos;s your call
                </span>
                <RulesBottomSliver
                    className="
                        absolute
                        bottom-0
                        translate-y-[calc(100%_-_1px)]
                    "
                    style={{
                        color: CreateHeadlineEventEditor.Heading.backgroundColor,
                    }}
                />
            </div>
            <button
                className="
                    w-full
                    mb-3
                    flex
                    items-center
                    justify-center
                    gap-2
                    relative
                    font-semibold
                    font-poppins
                    text-base
                    py-2
                "
                onClick={() => void room.data.write({gameUIState: GameUIState.HEADLINE}, true)}
                style={{
                    backgroundColor: CreateHeadlineEventEditor.CallToAction.backgroundColor,
                    color: CreateHeadlineEventEditor.CallToAction.textColor,
                }}
            >
                <PenIcon />
                Create a Headline!
                <StartButtonSliver
                    className="
                        absolute
                        bottom-0
                        left-0
                        right-0
                        w-full
                        translate-y-[calc(100%_-_1px)]
                    "
                    style={{
                        color: CreateHeadlineEventEditor.CallToAction.backgroundColor,
                    }}
                />
            </button>
        </div>
    );
};

const RoundStart = ({
    editor,
    round,
    state,
    theme,
    eventRef,
}: GameNodeProps<Type.ROUND_START>) =>
{
    const {RoundTag} = theme.data.ApplicationSettings.GameContainer;

    return (
        <>
            <div
                className="flex items-center justify-center skew-x-12"
                style={{
                    paddingTop: RoundTag.parentVerticalPaddingTop,
                    paddingBottom: RoundTag.parentVerticalPaddingBottom
                }}
                ref={eventRef}
            >
                <div className="flex drop-shadow-md">
                    <div
                        className="px-[10px] font-poppins font-normal text-base text-center"
                        style={{
                            backgroundColor: RoundTag.backgroundColor,
                            color: RoundTag.textColor
                        }}
                    >
                        <span className="inline-block -skew-x-12">
                            {`Round ${round.number}`}
                        </span>
                    </div>
                </div>
            </div>
            { (state === EventState.ACTIVE) && <CreateHeadlineControls theme={theme} /> }
            { (state === EventState.STICKY) && (
                <SystemMessage
                    theme={theme}
                    message={`*${editor.name}* is writing the next big front-page headline`}
                />
            )}
        </>
    );
};

export default RoundStart;
