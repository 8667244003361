import { Type as Event} from "./Event.js";
import Phase from "./Phase.js";

enum Validity
{
    NEVER = 0,

    IN_LOBBY = 1 << 0,
    IN_GAME = 1 << 1,
    AFTER_GAME = 1 << 2,

    // eslint-disable-next-line @typescript-eslint/prefer-literal-enum-member
    ALWAYS = IN_LOBBY | IN_GAME | AFTER_GAME,
}

const E = Event;
const V = Validity;

const ValidityMap: {
    readonly [Succeeding in Event]: { // <-- Event that is happening NOW
        readonly [Preceding in Event]: Validity //<-- Events that could have happened BEFORE
    }
} = {
    [E.GAME_START]: {
        // _ > (A)
        [E.GAME_START]: V.NEVER,
        [E.GAME_END]: V.NEVER,

        [E.PLAYER_JOIN]: V.NEVER,
        [E.SETTINGS_CHANGE]: V.NEVER,
        [E.PLAYER_LEAVE]: V.NEVER,
        [E.CHAT]: V.NEVER,

        [E.ROUND_START]: V.NEVER,
        [E.HEADLINE_CREATE]: V.NEVER,
        [E.PHOTO_SUBMIT]: V.NEVER,
        [E.VOTE_START]: V.NEVER,
        [E.VOTE_RESULT]: V.NEVER,
        [E.ROUND_SKIP]:V.NEVER,
    },

    [E.PLAYER_JOIN]: {
        // ABCDF > (B)
        [E.GAME_START]: V.IN_LOBBY,
        [E.GAME_END]: V.NEVER,

        [E.PLAYER_JOIN]: V.IN_LOBBY,
        [E.SETTINGS_CHANGE]: V.IN_LOBBY,
        [E.PLAYER_LEAVE]: V.IN_LOBBY,
        [E.CHAT]: V.IN_LOBBY,

        [E.ROUND_START]: V.NEVER,
        [E.HEADLINE_CREATE]: V.NEVER,
        [E.PHOTO_SUBMIT]: V.NEVER,
        [E.VOTE_START]: V.NEVER,
        [E.VOTE_RESULT]: V.NEVER,
        [E.ROUND_SKIP]:V.NEVER,

    },

    [E.CHAT]: {
        // BCDFGHIJK > (C)
        [E.GAME_START]: V.NEVER,
        [E.GAME_END]: V.NEVER,

        [E.PLAYER_JOIN]: V.IN_LOBBY,
        [E.SETTINGS_CHANGE]: V.IN_LOBBY,
        [E.PLAYER_LEAVE]: V.ALWAYS,
        [E.CHAT]: V.ALWAYS,

        [E.ROUND_START]: V.IN_GAME,
        [E.HEADLINE_CREATE]: V.IN_GAME,
        [E.PHOTO_SUBMIT]: V.IN_GAME,
        [E.VOTE_START]: V.IN_GAME,
        [E.VOTE_RESULT]: V.IN_GAME,
        [E.ROUND_SKIP]:V.NEVER,
    },

    [E.PLAYER_LEAVE]: {
        // BCDFGHIJK > (D)
        [E.GAME_START]: V.NEVER,
        [E.GAME_END]: V.NEVER,

        [E.PLAYER_JOIN]: V.IN_LOBBY,
        [E.SETTINGS_CHANGE]: V.IN_LOBBY,
        [E.PLAYER_LEAVE]: V.ALWAYS,
        [E.CHAT]: V.ALWAYS,

        [E.ROUND_START]: V.IN_GAME,
        [E.HEADLINE_CREATE]: V.IN_GAME,
        [E.PHOTO_SUBMIT]: V.IN_GAME,
        [E.VOTE_START]: V.IN_GAME,
        [E.VOTE_RESULT]: V.IN_GAME,
        [E.ROUND_SKIP]:V.NEVER,


    }, //state=7, last=7, now=4

    [E.GAME_END]: {
        // BCDFGHIJK > (E)
        [E.GAME_START]: V.NEVER,
        [E.GAME_END]: V.NEVER,

        [E.PLAYER_JOIN]: V.AFTER_GAME,
        [E.SETTINGS_CHANGE]: V.AFTER_GAME,
        [E.PLAYER_LEAVE]: V.AFTER_GAME,
        [E.CHAT]: V.AFTER_GAME,

        [E.ROUND_START]: V.AFTER_GAME,
        [E.HEADLINE_CREATE]: V.AFTER_GAME,
        [E.PHOTO_SUBMIT]: V.AFTER_GAME,
        [E.VOTE_START]: V.AFTER_GAME,
        [E.VOTE_RESULT]: V.AFTER_GAME,
        [E.ROUND_SKIP]: V.AFTER_GAME,
    },

    [E.SETTINGS_CHANGE]: {
        // BCDF > (F)
        [E.GAME_START]: V.NEVER,
        [E.GAME_END]: V.NEVER,

        [E.PLAYER_JOIN]: V.IN_LOBBY,
        [E.SETTINGS_CHANGE]: V.IN_LOBBY,
        [E.PLAYER_LEAVE]: V.IN_LOBBY,
        [E.CHAT]: V.IN_LOBBY,

        [E.ROUND_START]: V.NEVER,
        [E.HEADLINE_CREATE]: V.NEVER,
        [E.PHOTO_SUBMIT]: V.NEVER,
        [E.VOTE_START]: V.NEVER,
        [E.VOTE_RESULT]: V.NEVER,
        [E.ROUND_SKIP]: V.NEVER,

    },

    [E.ROUND_START]: {
        // BCDFK > (G)
        [E.GAME_START]: V.NEVER,
        [E.GAME_END]: V.NEVER,

        [E.PLAYER_JOIN]: V.IN_GAME,
        [E.SETTINGS_CHANGE]: V.IN_GAME,
        [E.PLAYER_LEAVE]: V.IN_GAME,
        [E.CHAT]: V.IN_GAME,

        [E.ROUND_START]: V.NEVER,
        [E.HEADLINE_CREATE]: V.NEVER,
        [E.PHOTO_SUBMIT]: V.NEVER,
        [E.VOTE_START]: V.NEVER,
        [E.VOTE_RESULT]: V.IN_GAME,
        [E.ROUND_SKIP]: V.IN_GAME,
    },

    [E.HEADLINE_CREATE]: {
        // G > (H)
        [E.GAME_START]: V.NEVER,
        [E.GAME_END]: V.NEVER,

        [E.PLAYER_JOIN]: V.NEVER,
        [E.SETTINGS_CHANGE]: V.NEVER,
        [E.PLAYER_LEAVE]: V.IN_GAME,
        [E.CHAT]: V.IN_GAME,

        [E.ROUND_START]: V.IN_GAME,
        [E.HEADLINE_CREATE]: V.NEVER,
        [E.PHOTO_SUBMIT]: V.NEVER,
        [E.VOTE_START]: V.NEVER,
        [E.VOTE_RESULT]: V.NEVER,
        [E.ROUND_SKIP]: V.NEVER,

    },

    [E.PHOTO_SUBMIT]: {
        // CDIH > (I)
        [E.GAME_START]: V.NEVER,
        [E.GAME_END]: V.NEVER,

        [E.PLAYER_JOIN]: V.NEVER,
        [E.SETTINGS_CHANGE]: V.NEVER,
        [E.PLAYER_LEAVE]: V.IN_GAME,
        [E.CHAT]: V.IN_GAME,

        [E.ROUND_START]: V.NEVER,
        [E.HEADLINE_CREATE]: V.IN_GAME,
        [E.PHOTO_SUBMIT]: V.IN_GAME,
        [E.VOTE_START]: V.NEVER,
        [E.VOTE_RESULT]: V.NEVER,
        [E.ROUND_SKIP]: V.NEVER,

    },

    [E.VOTE_START]: {
        // CDI > (J)
        [E.GAME_START]: V.NEVER,
        [E.GAME_END]: V.NEVER,

        [E.PLAYER_JOIN]: V.NEVER,
        [E.SETTINGS_CHANGE]: V.NEVER,
        [E.PLAYER_LEAVE]: V.IN_GAME,
        [E.CHAT]: V.IN_GAME,

        [E.ROUND_START]: V.NEVER,
        [E.HEADLINE_CREATE]: V.NEVER,
        [E.PHOTO_SUBMIT]: V.IN_GAME,
        [E.VOTE_START]: V.NEVER,
        [E.VOTE_RESULT]: V.NEVER,
        [E.ROUND_SKIP]: V.NEVER,

    },

    [E.VOTE_RESULT]: {
        // CDJ > (K)
        [E.GAME_START]: V.NEVER,
        [E.GAME_END]: V.NEVER,

        [E.PLAYER_JOIN]: V.NEVER,
        [E.SETTINGS_CHANGE]: V.NEVER,
        [E.PLAYER_LEAVE]: V.IN_GAME,
        [E.CHAT]: V.IN_GAME,

        [E.ROUND_START]: V.NEVER,
        [E.HEADLINE_CREATE]: V.NEVER,
        [E.PHOTO_SUBMIT]: V.NEVER,
        [E.VOTE_START]: V.IN_GAME,
        [E.VOTE_RESULT]: V.NEVER,
        [E.ROUND_SKIP]: V.NEVER,
    },
    [E.ROUND_SKIP]: {
        // CDJ > ()
        [E.GAME_START]: V.NEVER,
        [E.GAME_END]: V.NEVER,

        [E.PLAYER_JOIN]: V.NEVER,
        [E.SETTINGS_CHANGE]: V.NEVER,
        [E.PLAYER_LEAVE]: V.IN_GAME,
        [E.CHAT]: V.IN_GAME,

        [E.ROUND_START]: V.IN_GAME,
        [E.HEADLINE_CREATE]: V.IN_GAME,
        [E.PHOTO_SUBMIT]: V.IN_GAME,
        [E.VOTE_START]: V.IN_GAME,
        [E.VOTE_RESULT]: V.IN_GAME,
        [E.ROUND_SKIP]: V.NEVER,
    },

    /**
     * @NOTICE
     *
     * Do not add things to this map without checking the conditions first.
     * This does not imply that there cannot be bugs, just make an effort
     */
};

export const check = (
    state: Phase,
    before: Event,
    now: Event,
) =>
{
    let phase: Validity;

    /**
     * @NOTE This translates game state to a game 'Section' ()
     * Is the state enum correct as it stands? We might not need it
     */
    switch (state)
    {
        case Phase.LOBBY:
            phase = Validity.IN_LOBBY;

            break;

        case Phase.HEADLINE_CREATION:
        case Phase.PHOTO_SUBMISSION:
        case Phase.VOTING:
            phase = Validity.IN_GAME;

            break;

        case Phase.CLOSED:
            phase = Validity.AFTER_GAME;

            break;
    }

    return Boolean(ValidityMap[now][before] & phase);
};
