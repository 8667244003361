import type { GetRoomEventsResponse, GetViewerDataRequest } from "@/realize-shared/model/RequestTypes";
import type { ObjectID, RoomID } from "@/realize-shared/model/Types";
import * as Requests from "./Requests";

const SendViewGameReq = async (
    room: RoomID,
    lastEventSeen: ObjectID | null,
) => Requests.post<GetViewerDataRequest, GetRoomEventsResponse>(
    false,
    "viewer/getRoomData",
    { room, last:lastEventSeen?? "" }
);

export default SendViewGameReq;
