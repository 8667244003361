import { useGameContext } from "@/game/GameContext";
import type { Type } from "@/realize-shared/model/Event";
import { formatTime } from "@/util/Util";
import type { GameNodeProps } from "../GameNodeProps";

const blockStyle = Wind.toggle({
    base: {
        display: "flex",
        paddingX: "px-2",
    },
    truthy: {
        justifyContent: "justify-end",
    },
    falsy: { },
});

const bubbleStyle = Wind.toggle({
    base: {
        color: "text-white",
        display: "inline-block",
        minWidth: "min-w-[32%]",
        maxWidth: "max-w-[66.6%]",
        padding: "p-2",
        paddingBottom: "pb-6",
        borderRadius: "rounded-xl",
        wordBreak: "break-words",
        position: "relative",
    },
    truthy: {
        borderTopRightRadius: "rounded-tr-[4px]"
    },
    falsy: {
        borderTopLeftRadius: "rounded-tl-[4px]"
    },
});

const ChatMessage = ({
    time,
    sender,
    message,
    isSameAsPrevious,
    theme,
    eventRef,
}: GameNodeProps<Type.CHAT>) =>
{
    const {
        self,
    } = useGameContext();

    const own = sender === self;

    const {ChatMessage} = theme.data.ApplicationSettings.GameContainer;

    const {IncomingMessage, OutgoingMessage} = ChatMessage;

    return (
        <div
            className={blockStyle.class(own)}
            style={{
                marginTop: isSameAsPrevious ? "4px" : "8px",
            }}
            ref={eventRef}
        >
            {!own && (
                isSameAsPrevious ? <div className="w-9 mr-2" /> : (
                    <img
                        src={sender.avatar.path}
                        alt={sender.avatar.alt}
                        className="
                            w-9
                            h-9
                            rounded-xl
                            mr-2
                        "
                    />
                )
            )}
            <div
                className={bubbleStyle.class(own)}

                style={{
                    backgroundColor: own ? OutgoingMessage.backgroundColor : IncomingMessage.backgroundColor,
                }}
            >
                {!own && (
                    <div className="flex justify-between items-center">
                        {!isSameAsPrevious
                            ? (
                                <b
                                    style={{
                                        color: IncomingMessage.accentColor
                                    }}
                                >
                                    {sender.name}
                                </b>
                            )
                            : (
                                <div />
                            )
                        }
                    </div>
                )}
                {message}
                <span className="absolute bottom-2 right-2 text-white text-opacity-60 text-sm font-poppins">
                    {formatTime(time)}
                </span>
            </div>
        </div>
    );
};

export default ChatMessage;
