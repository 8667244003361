// https://stackoverflow.com/a/34577886
export const float = () =>
{
    /* eslint-disable @typescript-eslint/no-magic-numbers*/

    const buffer = new ArrayBuffer(8);

    const ints = new Int8Array(buffer);

    crypto.getRandomValues(ints);

    ints[7] = 63;
    ints[6] |= 0xf0;

    return new DataView(buffer).getFloat64(0, true) - 1;

    /* eslint-enable @typescript-eslint/no-magic-numbers*/
};

/**
 * Returns a random integer
 *
 * if 0 args are defined, the range is [0, MAX_SAFE_INTEGER)
 * if 1 arg is defined, the range is [0, bound)
 * if 2 args are defined, the range is [bound, max)
 *
 * @param bound upper or lower bound
 * @param max upper bound
 */
export const integer = (bound?: number, max?: number) =>
{
    const hasMin = typeof bound !== "undefined";
    const hasMax = typeof max !== "undefined";

    const lower = (hasMin === hasMax)? (bound ?? 0): 0;
    const upper = (hasMax)? max: (bound ?? Number.MAX_SAFE_INTEGER);

    if (lower >= upper)
    {
        throw new Error(`Bound Error: Lower must be less upper. [ ${lower}, ${upper} )`);
    }

    return Math.floor((float() * (upper - lower)) + lower);
};

/**
 * A 32 bit seed-able prng
 * @param seed the seed for the prng
 * @returns a prng generator that generates 32bit integers
 *
 * @link https://github.com/bryc/code/blob/master/jshash/PRNGs.md#splitmix32
 */
export const splitMix32 = (seed = 0) => () =>
{
    /* eslint-disable @typescript-eslint/no-magic-numbers*/

    seed |= 0;
    seed = seed + 0x9e3779b9 | 0;

    let t = seed ^ seed >>> 16;

    t = Math.imul(t, 0x21f0aaad);
    t = t ^ t >>> 15;
    t = Math.imul(t, 0x735a2d97);

    return ((t = t ^ t >>> 15) >>> 0);

    /* eslint-enable @typescript-eslint/no-magic-numbers*/
};

/**
 * A 32 bit seed-able prng
 * @param seed the seed for the prng
 * @returns a prng generator that returns floats
 *
 * @link https://github.com/bryc/code/blob/master/jshash/PRNGs.md#splitmix32
 */
export const splitMix32F = (seed = 0) =>
{
    /* eslint-disable @typescript-eslint/no-magic-numbers*/
    const rand = splitMix32(seed);

    return () => rand() / 4294967296;
    /* eslint-enable @typescript-eslint/no-magic-numbers*/
};
