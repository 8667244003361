import type { Settings } from "./Types";
import * as Time from "./Time.js";

const settings: Settings = {
    pointsToWin: 5,
    roundLength: 10 * Time.MS_IN_M,
    visibility: "private",
};

export default settings;
