import { sanitizer } from "@/realize-shared/Sanitization";

// sanitize, and place cursor in correct place
export const sanitizeInputElement = (
    element: HTMLInputElement | HTMLTextAreaElement,
    replacer: RegExp | ((value: string) => string),
    preprocess?: (input: string) => string,
    postprocess?: (input: string) => string,
) =>
{
    if (typeof replacer !== "function") replacer = sanitizer([replacer]);

    const { selectionEnd } = element;
    const moveCursor = selectionEnd !== null;

    let cursor = element.value.length - (selectionEnd ?? 0);
    let text = element.value;

    if (preprocess) text = preprocess(text);

    text = replacer(text);

    if (postprocess) text = postprocess(text);

    element.value = text;

    if (moveCursor)
    {
        cursor = text.length - cursor;
        element.setSelectionRange(cursor, cursor);
    }

    return text;
};
