import type { Player } from "./types/Game";
import type { ThemePack } from "./ShopItem";

const NonePlayer: Readonly<Player> = {
    id: "",
    name: "",
    score: 0,
    avatar: {
        alt: "avatar",
        hash: "0000000000000000000000000000000000000000",
        // 1x1px transparent png
        // eslint-disable-next-line @stylistic/max-len
        path: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVQIW2NgYGBgAAAABQABOz4IWwAAAABJRU5ErkJggg=="
    },
    theme: null as unknown as ThemePack,
};

export default NonePlayer;
