import React from "react";
import { sanitizeInputElement } from "@/util/ElementSanitation";
import { sanitizeAZDash } from "@/realize-shared/Sanitization";
import {ReactComponent as ErrorIcon} from "@/img/symbolic/error-icon.svg";
import { twMerge } from "tailwind-merge";

export type CodeEntryRef = {
    value: string;
};

type CodeEntryProps = {
    error?: string;
    className?: string;
    onChanged?: (value: string) => void;
    onEnter?: () => void;
};

const CodeEntry = React.forwardRef<CodeEntryRef, CodeEntryProps>(
    function RoomCodeEntry({
        error,
        className,
        onChanged,
        onEnter,
    }: CodeEntryProps, ref)
    {
        const [value, setValue] = React.useState("");

        React.useImperativeHandle(ref, () =>
        {
            return {
                value: value,
            };
        });

        return (
            <div className={twMerge("flex-grow relative -top-2", className)}>
                <div
                    className={`
                        flex
                        justify-between
                        ${error ? "text-sharp-red" : "text-white text-opacity-[0.72]"}
                        font-poppins
                        leading-6
                    `}
                    style={{
                        letterSpacing: "-0.026rem"
                    }}
                >
                    <span>
                        Join Code
                    </span>
                    {error && (
                        <div className="flex gap-1 items-center">
                            <ErrorIcon />
                            {error}
                        </div>
                    )}
                </div>
                <div>
                    <input
                        type="text"
                        className={`
                            bg-transparent
                            text-white
                            w-full
                            border-b-2
                            ${error ? "border-sharp-red" : "border-white border-opacity-20"}
                            font-poppins
                            font-bold
                            focus:border-opacity-100
                            focus:outline-none
                        `}
                        placeholder="ABC-XYZ"
                        value={value}
                        onChange={(e) =>
                        {
                            const grow = value.length < e.target.value.length;
                            const mid = 3;
                            const max = 6;

                            const sanitized = sanitizeInputElement(
                                e.currentTarget,
                                sanitizeAZDash,
                                void 0,
                                (input) =>
                                {
                                    if (!grow) return input.replaceAll(/-+/gu, "-");

                                    input = input.replaceAll("-", "");

                                    if (input.length < mid) return input;

                                    return `${input.substring(0, mid)}-${input.substring(mid, max)}`;
                                }
                            );

                            void setValue(sanitized);

                            if (onChanged) onChanged(sanitized);
                        }}
                        onKeyUp={(e) =>
                        {
                            if (e.key === "Enter" && onEnter) onEnter();
                        }}
                    />
                </div>
            </div>
        );
    }
);

export default CodeEntry;
