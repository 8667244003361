// DO NOT EDIT THIS FILE
// IT IS GENERATED AT BUILD

import a$0 from "../img/default_magazine.webp";
import a$1 from "../img/audience-icon.svg";
import a$2 from "../img/logo.svg";
import a$3 from "../img/loading-wheel.gif";
import a$4 from "../img/crown.svg";
import a$5 from "../img/jobo-beta.webp";
import a$6 from "../img/editor-symbol.svg";
import a$7 from "../img/magazine/barcode.png";
import a$8 from "../img/magazine/logo.png";
import a$9 from "../img/store/hanger.svg";
import a$10 from "../img/store/cardboard-texture.png";
import a$11 from "../img/store/section-arrow.svg";
import a$12 from "../img/store/store-special-offer.svg";
import a$13 from "../img/store/price-tag-right-pressed.png";
import a$14 from "../img/store/bg-pattern.png";
import a$15 from "../img/store/pack-background.svg";
import a$16 from "../img/store/price-tag-left.png";
import a$17 from "../img/store/card-title-slice.svg";
import a$18 from "../img/store/price-tag-center.png";
import a$19 from "../img/store/price-tag-right.png";
import a$20 from "../img/store/price-tag-center-pressed.png";
import a$21 from "../img/store/price-tag-left-pressed.png";
import a$22 from "../img/chat/photos-in-sliver-bottom.svg";
import a$23 from "../img/chat/voting-alert-decal.svg";
import a$24 from "../img/chat/scoreboard-bottom-sliver.svg";
import a$25 from "../img/chat/inline-playerlist-bottom-decal.svg";
import a$26 from "../img/chat/round-pop-up-black-underlay.svg";
import a$27 from "../img/chat/expand.svg";
import a$28 from "../img/chat/scoreboard-points.svg";
import a$29 from "../img/chat/collapse.svg";
import a$30 from "../img/chat/circle-6x6.svg";
import a$31 from "../img/chat/bottom-submission-sliver.svg";
import a$32 from "../img/chat/top-submission-sliver.svg";
import a$33 from "../img/chat/bottom-rules-sliver.svg";
import a$34 from "../img/chat/send.svg";
import a$35 from "../img/chat/pen-icon.svg";
import a$36 from "../img/chat/top-headline-sliver.svg";
import a$37 from "../img/chat/bottom-headline-sliver.svg";
import a$38 from "../img/chat/bottom-start-sliver.svg";
import a$39 from "../img/chat/scoreboard-top-sliver.svg";
import a$40 from "../img/chat/photos-in-sliver-top.svg";
import a$41 from "../img/chat/scoreboard-bottom-list.svg";
import a$42 from "../img/icons/animated-r.svg";
import a$43 from "../img/icons/permissions-bell.svg";
import a$44 from "../img/icons/permissions-camera.svg";
import a$45 from "../img/viewer/landing-top-sliver.svg";
import a$46 from "../img/viewer/landing-bottom-sliver.svg";
import a$47 from "../img/backgrounds/halftone-header.webp";
import a$48 from "../img/backgrounds/space.webp";
import a$49 from "../img/backgrounds/viewer-background.webp";
import a$50 from "../img/backgrounds/how-to-background.webp";
import a$51 from "../img/backgrounds/home-bg.webp";
import a$52 from "../img/backgrounds/tutorial-bg.webp";
import a$53 from "../img/backgrounds/mountains.webp";
import a$54 from "../img/backgrounds/hatches.webp";
import a$55 from "../img/layout/modal-close-button-bg.svg";
import a$56 from "../img/layout/black-triangle.svg";
import a$57 from "../img/layout/raw-jagged-button.svg";
import a$58 from "../img/layout/join-bottom-fray.svg";
import a$59 from "../img/layout/top-bar-bg.png";
import a$60 from "../img/layout/no-history-background-badge.svg";
import a$61 from "../img/layout/heading-background.svg";
import a$62 from "../img/layout/history-background.png";
import a$63 from "../img/layout/heading-decor.svg";
import a$64 from "../img/layout/join-top-fray.svg";
import a$65 from "../img/layout/jagged-button-poppy.svg";
import a$66 from "../img/layout/pop-up-top-bar.svg";
import a$67 from "../img/layout/how-to-button-bg.svg";
import a$68 from "../img/layout/jagged-button-red.svg";
import a$69 from "../img/layout/join-cancel-button.svg";
import a$70 from "../img/layout/menu/barcode.svg";
import a$71 from "../img/layout/menu/article-image.png";
import a$72 from "../img/layout/login/login-mountain.webp";
import a$73 from "../img/layout/login/top-left-shape.svg";
import a$74 from "../img/layout/login/account-button-bg.svg";
import a$75 from "../img/layout/identity-selector/pack-price-bg.svg";
import a$76 from "../img/layout/identity-selector/top-arrow.svg";
import a$77 from "../img/layout/store/headline-icon.svg";
import a$78 from "../img/layout/store/item-background.svg";
import a$79 from "../img/layout/store/unlocked.svg";
import a$80 from "../img/layout/history/date-background.svg";
import a$81 from "../img/layout/history/stats-background.svg";
import a$82 from "../img/layout/history/date-icon.svg";
import a$83 from "../img/layout/game/clock.svg";
import a$84 from "../img/layout/game/canvas-color-selector-bg.svg";
import a$85 from "../img/layout/game/winner-card-bg.svg";
import a$86 from "../img/layout/game/right-swipe-bg.png";
import a$87 from "../img/layout/game/left-swipe-bg.png";
import a$88 from "../img/layout/game/create-headline-background.svg";
import a$89 from "../img/layout/game/winner.png";
import a$90 from "../img/layout/game/position-card-bg.svg";
import a$91 from "../img/layout/shared/prompt-bg.svg";
import a$92 from "../img/layout/shared/create-add.svg";
import a$93 from "../img/layout/shared/square-button-bg.svg";
import a$94 from "../img/symbolic/info-icon.svg";
import a$95 from "../img/symbolic/home.svg";
import a$96 from "../img/symbolic/error-icon.svg";
import a$97 from "../img/symbolic/social-share.svg";
import a$98 from "../img/symbolic/clock.svg";
import a$99 from "../img/symbolic/caret.svg";
import a$100 from "../img/symbolic/light-bulb.svg";
import a$101 from "../img/symbolic/settings-cog.svg";
import a$102 from "../img/symbolic/store.svg";
import a$103 from "../img/symbolic/pencil.svg";
import a$104 from "../img/symbolic/capture.svg";
import a$105 from "../img/symbolic/share.svg";
import a$106 from "../img/symbolic/create-room-plus.svg";
import a$107 from "../img/symbolic/caption-letters.svg";
import a$108 from "../img/symbolic/close.svg";
import a$109 from "../img/symbolic/thin-check.svg";
import a$110 from "../img/symbolic/chevron-right.svg";
import a$111 from "../img/symbolic/kick.svg";
import a$112 from "../img/symbolic/quit.svg";
import a$113 from "../img/symbolic/man-run-door.svg";
import a$114 from "../img/symbolic/dice.svg";
import a$115 from "../img/symbolic/photo-retake.svg";
import a$116 from "../img/symbolic/loader.svg";
import a$117 from "../img/symbolic/history.svg";
import a$118 from "../img/symbolic/copy.svg";
import a$119 from "../img/symbolic/flip.svg";
import a$120 from "../img/symbolic/caption-trash.svg";
import a$121 from "../img/symbolic/play.svg";
import a$122 from "../img/symbolic/chevron-left.svg";
import a$123 from "../img/symbolic/cross.svg";
import a$124 from "../img/symbolic/trash-can.svg";
import a$125 from "../img/symbolic/past-rounds.svg";
import a$126 from "../img/symbolic/flash.svg";
import a$127 from "../img/symbolic/trophy.svg";
import a$128 from "../img/tutorial/grunge-texture.webp";
import a$129 from "../img/tutorial/panel-4.webp";
import a$130 from "../img/tutorial/realize-logo.webp";
import a$131 from "../img/tutorial/how-to-play.webp";
import a$132 from "../img/tutorial/panel-3.webp";
import a$133 from "../img/tutorial/step-1-label.webp";
import a$134 from "../img/tutorial/settings-panel.webp";
import a$135 from "../img/tutorial/step-2-label.webp";
import a$136 from "../img/tutorial/panel-2.webp";
import a$137 from "../img/tutorial/step-4-label.webp";
import a$138 from "../img/tutorial/panel-1.webp";
import a$139 from "../img/tutorial/step-3-label.webp";

const assets = [
    a$0,
    a$1,
    a$2,
    a$3,
    a$4,
    a$5,
    a$6,
    a$7,
    a$8,
    a$9,
    a$10,
    a$11,
    a$12,
    a$13,
    a$14,
    a$15,
    a$16,
    a$17,
    a$18,
    a$19,
    a$20,
    a$21,
    a$22,
    a$23,
    a$24,
    a$25,
    a$26,
    a$27,
    a$28,
    a$29,
    a$30,
    a$31,
    a$32,
    a$33,
    a$34,
    a$35,
    a$36,
    a$37,
    a$38,
    a$39,
    a$40,
    a$41,
    a$42,
    a$43,
    a$44,
    a$45,
    a$46,
    a$47,
    a$48,
    a$49,
    a$50,
    a$51,
    a$52,
    a$53,
    a$54,
    a$55,
    a$56,
    a$57,
    a$58,
    a$59,
    a$60,
    a$61,
    a$62,
    a$63,
    a$64,
    a$65,
    a$66,
    a$67,
    a$68,
    a$69,
    a$70,
    a$71,
    a$72,
    a$73,
    a$74,
    a$75,
    a$76,
    a$77,
    a$78,
    a$79,
    a$80,
    a$81,
    a$82,
    a$83,
    a$84,
    a$85,
    a$86,
    a$87,
    a$88,
    a$89,
    a$90,
    a$91,
    a$92,
    a$93,
    a$94,
    a$95,
    a$96,
    a$97,
    a$98,
    a$99,
    a$100,
    a$101,
    a$102,
    a$103,
    a$104,
    a$105,
    a$106,
    a$107,
    a$108,
    a$109,
    a$110,
    a$111,
    a$112,
    a$113,
    a$114,
    a$115,
    a$116,
    a$117,
    a$118,
    a$119,
    a$120,
    a$121,
    a$122,
    a$123,
    a$124,
    a$125,
    a$126,
    a$127,
    a$128,
    a$129,
    a$130,
    a$131,
    a$132,
    a$133,
    a$134,
    a$135,
    a$136,
    a$137,
    a$138,
    a$139,
];

export default assets;
