import * as Requests from "./Requests";
import {
    GetEventRequest,
    GetRoomEventsResponse,
} from "@/realize-shared/model/RequestTypes";
import type { ObjectID, RoomID } from "@/realize-shared/model/Types";

export const getEvents = async (
    roomID: RoomID,
    lastEvent: ObjectID,
) => Requests.post<GetEventRequest, GetRoomEventsResponse>(
    false,
    "event/getEvents",
    { roomID, lastEvent },
);
