import * as React from "react";

import * as Util from "@/util/Util";
import RoundishButton from "@/components/shared/RoundishButton";
// import { Capacitor } from "@capacitor/core";

import { ReactComponent as IconCaret } from "@/img/symbolic/caret.svg";

const SHOW_CANCEL = false;

const GameSettingsSelection = ({
    icon,
    values,
    value,
    title,
    prefix,
    suffix,
    onSubmit,
    locked,
}: {
    locked?: boolean,
    icon: React.ReactNode;
    values: number[];
    value: number;
    title: string;
    prefix: string;
    suffix?: string;
    onSubmit: (value?: number) => void;
}) =>
{
    const enabled = !locked;

    const [isOpen, setIsOpen] = React.useState(false);

    // Auto scroll to value
    const scrollRef = React.useRef<HTMLDivElement>(null);

    const getScrollData = React.useCallback(() =>
    {
        const container = scrollRef.current;
        const first = container?.firstElementChild;

        if (!(first && first instanceof HTMLDivElement)) return false;

        const padding = first.offsetTop;
        const height = first.offsetHeight;
        const scroll = container.scrollTop;
        const last = values.length - 1;

        return {
            first,
            container,
            padding,
            height,
            scroll,
            last,
        };
    }, [values]);

    const onSelect = (e: React.MouseEvent<HTMLDivElement>) =>
    {
        const data = getScrollData();
        const self = e.currentTarget;

        if (!data) return;

        // Scroll to the element
        data.container.scrollTo({
            "top":      self.offsetTop - data.padding,
            "behavior": "auto",
        });
    };

    const closeModal = (valueToSubmit?: number) =>
    {
        setIsOpen(false);
        onSubmit(valueToSubmit);
    };

    const onConfirm = () =>
    {
        const data = getScrollData();

        if (!data) return;

        const index = Util.clamp(
            0,
            Math.floor(data.scroll / data.height),
            data.last,
        );

        closeModal(values[index]);
    };

    React.useEffect(() =>
    {
        const data = getScrollData();

        if (!data) return;

        const index = Util.clamp(0, values.indexOf(value), data.last);

        const top = data.height * index;

        data.container.scrollTo({
            top,
            "behavior": "instant",
        });

        ((isOpen)
            ? ((x: string) => void document.body.classList.add(x))
            : ((x: string) => void document.body.classList.remove(x))
        )("pop-over");
    }, [getScrollData, isOpen, value, values]);

    // const platform = Capacitor.getPlatform();

    return (
        <div
            className={`
                grid
                gap-3
            `}
        >
            <div
                onClick={() =>
                {
                    if (enabled) setIsOpen(!isOpen);
                }}
                className={`
                    ${enabled ? "bg-poppy-yellow" : "bg-[#D7DEE7]"}
                    rounded-lg
                    py-1
                    px-2
                    text-dark-blue
                    flex
                    items-center
                    justify-center
                    gap-1
                    font-bold
                    text-base
                    font-poppins
                `}
            >
                <div className="flex gap-1 items-center">
                    {icon}
                    {value}
                </div>
                {enabled && <IconCaret className="text-[#D2A650]" />}
            </div>
            {isOpen && (
                <div
                    className={`
                        fixed
                        inset-0
                        z-[75]
                        bg-black
                        bg-opacity-60
                    `}
                    style={{
                        // Set the bottom only on iOS
                        // bottom: platform === "ios" ? "var(--safe-area-bottom)" : 0,
                    }}
                    onClick={(e) =>
                    {
                        if (e.target === e.currentTarget) onConfirm();
                    }}
                >
                    <div
                        className={`
                            bg-parchment
                            absolute
                            bottom-0
                            left-0
                            right-0
                            h-[240px]
                            py-2
                            px-3
                            flex
                            flex-col
                        `}
                    >
                        <h1
                            className={`
                                text-dark-blue
                                text-lg
                                font-poppins
                                font-bold
                                flex-[0_0_43px]
                            `}
                        >
                            {title}
                        </h1>
                        <div
                            className={`
                                flex
                                flex-row
                                items-center
                                justify-center
                                h-full
                                relative
                            `}
                        >
                            <div
                                className={`
                                    absolute
                                    h-8
                                    w-full
                                    bg-sharp-red
                                    bg-opacity-10
                                    top-1/2
                                    -translate-y-1/2
                                    pointer-events-none
                                `}
                            />
                            <div
                                className={`
                                    text-dark-blue
                                    text-base
                                    font-semibold
                                    leading-6
                                    font-poppins
                                `}
                            >
                                {prefix}
                            </div>
                            <div
                                ref={scrollRef}
                                id="scroll-area"
                                className={`
                                    scroll-smooth
                                    snap-mandatory
                                    snap-y
                                    overflow-y-scroll
                                    h-24
                                    py-8
                                    no-scrollbar
                                `}
                            >
                                {values.map((text, key) => (
                                    <div
                                        key={key}
                                        className={`
                                            snap-center
                                            px-8
                                            h-8
                                            text-dark-blue
                                            text-base
                                            font-semibold
                                            leading-6
                                            font-poppins
                                            flex
                                            items-center
                                            justify-center
                                        `}
                                        onClick={onSelect}
                                    >
                                        {text}
                                    </div>
                                ))}
                            </div>
                            <div
                                className={`
                                    text-dark-blue
                                    text-base
                                    font-semibold
                                    leading-6
                                    font-poppins
                                `}
                            >
                                {suffix}
                            </div>
                        </div>
                        <div
                            className={`
                                grid
                                grid-cols-3
                                gap-2
                                pt-4
                            `}
                        >
                            <div />
                            {
                                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                                SHOW_CANCEL
                                    ? (
                                        <RoundishButton
                                            color="text-white"
                                            background="bg-dark-blue"
                                            onClick={closeModal}
                                            text="cancel"
                                        />
                                    )
                                    :(
                                        <div />
                                    )
                            }
                            <RoundishButton
                                color="text-white"
                                background="bg-sharp-red"
                                onClick={onConfirm}
                                text="confirm"
                            />
                        </div>
                        <div className="h-[--safe-area-bottom] flex-shrink-0" />
                    </div>
                </div>
            )}
        </div>
    );
};

export default GameSettingsSelection;
