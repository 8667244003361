import type { Player } from "@/game/types/Game";
import WinnerBanner from "@/img/layout/game/winner.png";
import { ReactComponent as IconCrownNew } from "@/img/crown.svg";
import PlayerCardBG from "@/img/layout/game/position-card-bg.svg";
import WinnerCardBG from "@/img/layout/game/winner-card-bg.svg";
import Ordinal from "@/util/Ordinal";
import { twMerge } from "tailwind-merge";

const FinalPositionCard = ({
    position,
}: {
    position: number;
}) => (
    <div
        className={`
            absolute
            top-0
            left-0
            w-11
            text-center
            -translate-y-2
            -translate-x-6
            bg-mint-green
            drop-shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)]
            -rotate-2
            font-bold
            py-2
            px-1
            text-dark-blue
        `}
    >
        <span
            className={`
                text-2xl
            `}
        >
            {position}
        </span>
        <span
            className={`
                align-super
                text-base
            `}
        >
            {Ordinal.from(position)}
        </span>
    </div>
);


const PlayerPositionCard = ({
    player,
    position,
    isWinner,
    animateIn,
    className,
}: {
    isWinner?: boolean;
    player: Player;
    position: number;
    className?: string;
    animateIn?: boolean;
}) => (
    <div
        className={twMerge(
            `
                aspect-[366/85]
                ml-8
                mr-4
                w-[calc(100vw_-_3rem)]
                max-w-[366px]
                h-auto
                bg-no-repeat
                bg-cover
                bg-center
                flex
                items-center
                justify-between
                px-6
                gap-3
                drop-shadow-[2px_2px_8px_rgba(0,0,0,0.60)]
                relative
                ${isWinner? "text-dark-blue": "text-white"}
                ${animateIn? "animate__animated animate__rubberBand animate_slideDownIn": ""}
            `,
            className,
        )}
        style={{
            "backgroundImage": `url(${isWinner ? WinnerCardBG: PlayerCardBG})`,
        }}
    >
        <div
            className={`
                rounded
                w-12
                h-12
                flex-shrink-0
                bg-cover
                bg-center
            `}
            style={{
                "backgroundImage": `url(${player.avatar.path})`,
            }}
        />
        <div
            className={`
                flex-grow
                font-poppins
                font-medium
                text-2xl
            `}
        >
            {player.name}
        </div>
        <div
            className={`
                font-poppins
                font-medium
                leading-[83%]
                text-base
            `}
        >
            <span
                className={`
                    font-bold
                    text-2xl
                `}
            >
                {player.score}
            </span>
            pts
        </div>
        <FinalPositionCard position={position} />
        {isWinner && (
            <IconCrownNew
                className={`
                    absolute
                    top-0
                    right-0
                    translate-x-4
                    -translate-y-8
                `}
            />
        )}
    </div>
);

export default function WinnerList({
    players,
    winner,
    ref
}: {
    players: Player[];
    winner: Player;
    ref: React.RefObject<HTMLDivElement>
})
{
    return (
        <div
            className="flex bg-red-500 h-[calc(100%_+_2rem)] items-center justify-center flex-col -mb-4"
            ref={ref}
        >
            <img
                src={WinnerBanner}
                alt="Winner"
                className="mb-4"
            />
            {players
                .sort((a, b) =>
                {
                    // Sort by score
                    if (a.score > b.score) return -1;
                    if (a.score < b.score) return 1;

                    // Sort by name
                    if (a.name < b.name) return -1;
                    if (a.name > b.name) return 1;

                    return 0;
                })
                .map((player) => ({
                    player,
                    "isWinner": player.id === winner.id,
                }))
                .map(({
                    player,
                    isWinner,
                }, index) => (
                    <PlayerPositionCard
                        key={player.id}
                        isWinner={isWinner}
                        player={player}
                        position={index + 1}
                        className={isWinner? "mb-12": ""}
                        animateIn={isWinner}
                    />
                ))}
        </div>
    );
}
