import type { Type } from "@/realize-shared/model/Event";
import type { GameNodeProps } from "../GameNodeProps";

const RoundSkip = (
    _event: GameNodeProps<Type.ROUND_SKIP>,
) =>
{
    return null;
};

export default RoundSkip;
