const BASE = 10;

export default class Ordinal
{
    public static readonly NORMAL = [
        "th", "st", "nd", "rd",
    ];

    public static readonly SUPER = [
        "ᵗʰ", "ˢᵗ", "ⁿᵈ", "ʳᵈ",
    ];

    public static from = (
        input: number,
        suffixes = Ordinal.NORMAL,
        base = BASE,
    ) =>
    {
        const twenties = base * 2;
        const hundreds = base ** 2;

        const part = input % hundreds;

        return suffixes[(part - twenties) % BASE] || suffixes[part] || suffixes[0];
    };

    // instanced version below

    private readonly suffixes: string[];

    private readonly base: number;

    public constructor (suffixes: string[], base = BASE)
    {
        this.suffixes = suffixes;
        this.base = base;
    }

    public from (input: number)
    {
        return Ordinal.from(input, this.suffixes, this.base);
    }
}
