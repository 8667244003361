import { MS_IN_S } from "./model/Time.js";

const BYTE_TIME = 0;
const BYTE_RAND = 4;
const BYTE_INCR = 9;

// we have 24 increment bits,
// drop the top bits, so the increment can be used as milliseconds
// 9 bits chosen as it is the largest number of bits that represent a number <= 1000
// const INCREMENT_BITS_TO_KEEP = 9;

// On second thought, we only use the value to sort, not to display. Keeping all the bits
const INCREMENT_BITS_TO_KEEP = 24;
const INCREMENT_MAX_VALUE = 2 ** INCREMENT_BITS_TO_KEEP;

export const getObjectIdData = (hex: string): [time: number, increment: number, random: bigint] =>
{
    const split = hex.match(/.{1,2}/gu) as string[];
    const mapped = split.map((byte) => parseInt(byte, 16));

    // pad to %4 bytes
    const timeBytes = [...mapped.slice(BYTE_TIME, BYTE_RAND)];
    const randomBytes = [0, 0, 0, ...mapped.slice(BYTE_RAND, BYTE_INCR)];
    const incrementBytes = [0, ...mapped.slice(BYTE_INCR)];

    const timeBuffer = Uint8Array.from(timeBytes).buffer;
    const randomBuffer = Uint8Array.from(randomBytes).buffer;
    const incrementBuffer = Uint8Array.from(incrementBytes).buffer;

    const time = new DataView(timeBuffer, 0).getUint32(0, false);
    const random = new DataView(randomBuffer, 0).getBigUint64(0, false);
    const increment = new DataView(incrementBuffer, 0).getUint32(0, false);

    return [
        time,
        increment,
        random,
    ];
};

export const getTimestampFromObjectId = (objectId: string) =>
{
    const [seconds, increment] = getObjectIdData(objectId);


    const fraction = (increment & (INCREMENT_MAX_VALUE-1)) / INCREMENT_MAX_VALUE;

    return (seconds + fraction) * MS_IN_S;
};
