import * as React from "react";

import * as Cache from "@/util/Cache";
import ReactDOM from "react-dom/client";
import { ADDRESS } from "@/api/Requests";
import * as FontLoader from "../util/FontLoader";
import AutoComplete from "@/game/AutoComplete";
import * as AppRequests from "@/api/AppRequests";
import Assets from "@/data/Assets.out";
import RealizeViewer from "./RealizeViewer";
import { Icons } from "@/game/Icons";
import { Themes } from "@/themes/ThemeLoader";
import "cordova-plugin-purchase";

// Next line using console to ensure Logger is not the problem when debugging
// eslint-disable-next-line no-console
console.log("Realize starting :)");

void (async () =>
{
    document.title = "initializing";

    Logger.debug({
        "server": ADDRESS,
    });

    document.title = "loading assets";

    await FontLoader.load({
        "f_Patrick_Hand": {
            "normal": {
                "400": new URL("../fonts/PatrickHand-Regular.ttf", import.meta.url).href,
            },
        },
        "f_Monoton": {
            "normal": {
                "400": new URL("../fonts/Monoton-Regular.ttf", import.meta.url).href,
            },
        },
        "f_Playfair_Display": {
            "normal": {
                "700": new URL("../fonts/PlayfairDisplay-Bold.ttf", import.meta.url).href,
            },
        },
        "f_Poppins": {
            "normal": {
                "300": new URL("../fonts/Poppins-Light.ttf", import.meta.url).href,
                "400": new URL("../fonts/Poppins-Regular.ttf", import.meta.url).href,
                "500": new URL("../fonts/Poppins-Medium.ttf", import.meta.url).href,
                "600": new URL("../fonts/Poppins-SemiBold.ttf", import.meta.url).href,
                "700": new URL("../fonts/Poppins-Bold.ttf", import.meta.url).href,
                "900": new URL("../fonts/Poppins-Black.ttf", import.meta.url).href,
            },
        },
        "f_Noto_Sans": {
            "normal": {
                "400": new URL("../fonts/NotoSans-Regular.ttf", import.meta.url).href,
                "600": new URL("../fonts/NotoSans-SemiBold.ttf", import.meta.url).href,
                "700": new URL("../fonts/NotoSans-Bold.ttf", import.meta.url).href,
                "800": new URL("../fonts/NotoSans-ExtraBold.ttf", import.meta.url).href,
            },
        },
        "f_Permanent_Marker": {
            "normal": {
                "500": new URL("../fonts/PermanentMarker-Regular.ttf", import.meta.url).href,
            }
        }
    });

    const blocking = [
        new URL("../img/layout/login/login-mountain.webp", import.meta.url).href,
    ];

    const loadMany = async (assets: string[]) =>
    {
        for await (const asset of assets)
        {
            await fetch(asset).catch(Logger.warn);
        }
    };

    // blocking assets
    await loadMany(blocking);

    // non-blocking assets
    loadMany(Assets).catch(Logger.error);

    document.title = "contacting server";

    const failed = await AppRequests.hello()
        .then((canConnect) =>
        {
            if (!canConnect) document.title = "connection failed";

            return !canConnect;
        })
        .catch(() =>
        {
            document.title = "cannot connect to server";

            Cache.remove("server");

            return true;
        });

    if (failed) return;

    document.title = "updating assets";


    await AutoComplete.load();
    await Icons.load();

    await Themes.load();
    document.title = "setting up";

    const container = document.createElement("div");

    container.id = "root";

    const root = ReactDOM.createRoot(container);

    document.title = "loading viewer";

    document.body.prepend(container);

    root.render(React.createElement(RealizeViewer));

    document.title = "Realize Web Viewer";
})().catch(Logger.error);
