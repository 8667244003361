import { getTimestampFromObjectId } from "../ObjectId.js";
import type { HumanID, ObjectID, Reason, Resource, Settings, Text, Version } from "./Types.js";

/**
 * The enum of event types.
 */
export enum Type
{
    GAME_START,
    // _ > A > B

    PLAYER_JOIN,
    // ABCDF > B > BCDEFG

    CHAT,
    // BCDFGHIJK > C > BCDEFGIJK

    PLAYER_LEAVE,
    // BCDFGHIJK > D > BCDEFGIJK

    GAME_END,
    // BCDFGHIJK > E > _

    SETTINGS_CHANGE,
    // BCDF > F > BCDEFG

    ROUND_START,
    // BCDFK > G > CDEH

    HEADLINE_CREATE,
    // G > H > CDEI

    PHOTO_SUBMIT,
    // CDIH > I > CDEIJ

    VOTE_START,
    // CDI > J > CDEK

    VOTE_RESULT,
    // CDJ > K > CDEG
    ROUND_SKIP,
}

/**
 * The event map.
 * This is a mapping of event types to their data types. (the data type is the second element in the tuple)
 * This is used to type the event data in the `Event` type.
 */
export interface EventMap extends Record<Type, unknown>
{
    [Type.GAME_START]: [
        serverVersionInfo: Version,
    ],
    [Type.PLAYER_JOIN]: [
        clientVersionInfo: Version,
        player: HumanID,
        name: Text,
        icon: Resource,
        theme: Resource,
    ],
    [Type.CHAT]: [
        sender: HumanID,
        message: Text,
    ],
    [Type.PLAYER_LEAVE]: [
        player: HumanID,
        host: HumanID,
        reason: Reason,
    ],
    [Type.GAME_END]: [
        reason: Reason,
    ],
    [Type.SETTINGS_CHANGE]: [
        changes: Settings,
    ],
    [Type.ROUND_START]: [
        editor: HumanID,
    ],
    [Type.HEADLINE_CREATE]: [
        headline: Text,
    ],
    [Type.PHOTO_SUBMIT]: [
        player: HumanID,
        photo: Resource,
    ],
    [Type.VOTE_START]: [
    ],
    [Type.VOTE_RESULT]: [
        winner: HumanID,
    ],
    [Type.ROUND_SKIP]:
    [
        reason:Reason
    ]
}

/**
 * The raw event type.
 */
export type RawEvent = [
    type: Type,
    id: ObjectID,
    ...data: unknown[],
];

/**
 * Parse the event data.
 * @param event The event type.
 * @param input The raw event data.
 * @returns The parsed event data.
 */
export const parse = <EventType extends Type>(
    type: EventType,
    data: RawEvent
) =>
{
    const [_type, id, ...eventData] = data;

    return {
        type,
        id,
        time: getTimestampFromObjectId(id),
        data: eventData as EventMap[EventType],
    };
};

/**
 * The event type.
 */
export type Event<EventType extends Type> = ReturnType<typeof parse<EventType>>;

export const serialize = <EventType extends Type>(event: Event<EventType>): string =>
{
    const rawEvent: RawEvent = [
        event.type,
        event.id,
        ...event.data,
    ];

    return JSON.stringify(rawEvent);
};
