import { ReactComponent as TopHeadlineSliver } from "@/img/chat/top-headline-sliver.svg";
import { ReactComponent as BottomHeadlineSliver } from "@/img/chat/bottom-headline-sliver.svg";
import { ReactComponent as PlayerListDecal } from "@/img/chat/inline-playerlist-bottom-decal.svg";
import { useGameContext } from "@/game/GameContext";
import type { Type } from "@/realize-shared/model/Event";
import { GameUIState } from "@/game/GameUIState";
import { EventState } from "@/game/events/ProcessedEventMap";
import StyledText from "@/chat/components/StyledText";
import type { GameNodeProps } from "../GameNodeProps";

const HeadlineMessage = ({
    id,
    state,
    headline,
    round,
    theme,
    eventRef,
}: GameNodeProps<Type.HEADLINE_CREATE>) =>
{
    const {
        room,
    } = useGameContext();

    AssertIsset(round, "Round is null");

    const { players } = round;

    const submitted = Array.from(round.submissions.keys())
        .map((id) =>
        {
            const player = players.get(id);

            AssertIsset(player, `Player not found ${id}`);

            return player;
        });

    const inactive = state !== EventState.ACTIVE;
    const sticky = state !== EventState.STATIC;

    const { HeadlineStatus } = theme.data.ApplicationSettings.GameContainer;

    return (
        <div
            className={`mb-2 pt-1 ${sticky ?"sticky -top-5 -bottom-5 z-[2] drop-shadow-[0px_0px_4px_#000]" : "z-0"}`}
            ref={eventRef}
        >
            <div
                className={`
                    px-4
                    pb-5
                    pt-4
                    mt-2
                    relative

                    font-poppins
                    text-base
                    leading-[150%]
                    font-normal

                    first-letter:text-5xl
                    first-letter:font-playfair
                    first-letter:float-left
                    first-letter:uppercase
                    first-letter:pr-1
                    first-letter:-mt-1
                `}

                style={{
                    backgroundColor: HeadlineStatus.Heading.backgroundColor,
                    color: HeadlineStatus.Heading.textColor,
                }}
            >
                <TopHeadlineSliver
                    className="
                        w-full
                        h-auto
                        aspect-[414_/_6]
                        absolute
                        top-[1px]
                        left-0
                        -translate-y-full
                    "
                    style={{
                        color: HeadlineStatus.Heading.backgroundColor,
                    }}
                />
                <StyledText
                    theme={theme}
                    text={headline}
                />
            </div>
            <div
                className="
                    flex
                    items-center
                    justify-center
                    pt-2
                    pb-1
                    gap-[10px]
                    w-full
                    z-[1]
                    relative
                    -mt-1
                "
                style={{
                    backgroundColor: HeadlineStatus.Status.backgroundColor,
                    color: HeadlineStatus.Status.textColor,
                }}
            >
                <BottomHeadlineSliver
                    className="
                        w-full
                        h-auto
                        aspect-[414_/_6]
                        absolute
                        top-[-1px]
                        left-0
                    "
                    style={{
                        color: HeadlineStatus.Heading.backgroundColor,
                    }}
                />
                {inactive && (
                    <>
                        {submitted.map(({avatar, name}, index) =>
                        {
                            return (
                                <img
                                    key={`avatar-${id}-${index}`}
                                    src={avatar.path}
                                    alt={name}
                                    className="rounded-full bg-[#424242] w-6 h-6"
                                />
                            );
                        })}
                        <span className="font-poppins font-semibold text-base leading-normal">
                            Submitted
                        </span>
                    </>
                )}
                {!inactive && (
                    <span
                        className="font-poppins font-semibold text-base leading-normal"
                        onClick={() =>
                        {
                            // @TODO make hit-box bigger
                            room.data.write({ gameUIState: GameUIState.CAMERA }, true);
                        }}
                    >
                        Take a photo!
                    </span>
                )}
            </div>
            <PlayerListDecal
                className="
                    w-full
                    h-auto
                    aspect-[414_/_6]
                    relative
                    -translate-y-[0.5px]
                "
                style={{
                    color: HeadlineStatus.Status.backgroundColor,
                }}
            />
        </div>
    );
};

export default HeadlineMessage;
