import LobbyControls from "@/chat/nodes/LobbyControls";
import RoundStart from "@/chat/nodes/RoundStart";
import ChatMessage from "@/chat/nodes/ChatMessage";
import HeadlineMessage from "@/chat/nodes/HeadlineMessage";
import SubmissionMessage from "@/chat/nodes/SubmissionMessage";
import PhotosAreIn from "@/chat/nodes/PhotosAreIn";
import RoundWinner from "@/chat/nodes/RoundWinner";
import PlayerJoinMessage from "@/chat/nodes/PlayerJoinMessage";
import PlayerLeaveMessage from "@/chat/nodes/PlayerLeaveMessage";
import GameEnd from "@/chat/nodes/GameEnd";
import RoundSkip from "@/chat/nodes/RoundSkip";
import RuleUpdate from "@/chat/nodes/RuleUpdate";

import { Type } from "@/realize-shared/model/Event";
import type { GameNodeProps } from "./GameNodeProps";

const GameNode = (event: GameNodeProps<Type>) =>
{
    switch (event.type)
    {
        case Type.GAME_START:
            return <LobbyControls {...event} />;

        case Type.ROUND_START:
            return <RoundStart {...event} />;

        case Type.HEADLINE_CREATE:
            return <HeadlineMessage {...event} />;

        case Type.VOTE_START:
            return <PhotosAreIn {...event} />;

        case Type.VOTE_RESULT:
            return <RoundWinner {...event} />;

        case Type.PHOTO_SUBMIT:
            return <SubmissionMessage {...event} />;

        case Type.CHAT:
            return <ChatMessage {...event} />;

        case Type.PLAYER_JOIN:
            return <PlayerJoinMessage {...event} />;

        case Type.PLAYER_LEAVE:
            return <PlayerLeaveMessage {...event} />;

        case Type.GAME_END:
            return <GameEnd {...event} />;

        case Type.ROUND_SKIP:
            return <RoundSkip {...event} />;

        case Type.SETTINGS_CHANGE:
            return <RuleUpdate {...event} />;
    }
};

export default GameNode;
