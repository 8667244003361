import React from "react";

const Aspect = ({
    aspect: { w: aspectW, h: aspectH },
    children,
    ...props
}: {
    aspect: { w: number, h: number },
} & React.ComponentProps<"div">) =>
{
    const containerRef = React.useRef<HTMLDivElement>(null);

    const [outerW, setOuterW] = React.useState(0);
    const [outerH, setOuterH] = React.useState(0);
    const [sizeW, setSizeW] = React.useState(0);
    const [sizeH, setSizeH] = React.useState(0);

    React.useLayoutEffect(() =>
    {
        const parent = containerRef.current?.parentElement;

        if (!parent) return;

        const obs = new ResizeObserver((entries) =>
        {
            if (entries.length <= 0) return;

            const { blockSize, inlineSize } = entries[entries.length - 1].contentBoxSize[0];

            setOuterW(inlineSize);
            setOuterH(blockSize);
        });

        obs.observe(parent);

        return () => void obs.disconnect();
    }, [containerRef]);

    React.useLayoutEffect(() =>
    {
        let innerW = Math.max(1, outerW);
        let innerH = Math.max(1, outerH);

        if (innerW / innerH < aspectW / aspectH)
        {
            innerH = Math.floor((outerW/aspectW)*aspectH);
        }
        else
        {
            innerW = Math.floor((outerH/aspectH)*aspectW);
        }

        setSizeW(innerW);
        setSizeH(innerH);
    }, [outerW, outerH, aspectW, aspectH]);

    return (
        <div
            {...props}
            ref={containerRef}
            style={{
                // eslint-disable-next-line react/prop-types
                ...(props.style ?? {}),
                width: `${sizeW}px`,
                height: `${sizeH}px`,
            }}
        >
            {children}
        </div>
    );
};

export default Aspect;
