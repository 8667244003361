import GameNode from "@/chat/GameNode";
import { useGameContext } from "@/game/GameContext";
import { Type } from "@/realize-shared/model/Event";
import React from "react";
import { LowerLobbyControls } from "./nodes/LobbyControls";
import { twMerge } from "tailwind-merge";

const gradientBackground = (hexValue: string, topToBottom: boolean = true) =>
{
    const baseHexLength = 8;

    if (hexValue.length > baseHexLength)
    {
        // Remove the alpha channel
        hexValue = hexValue.substring(0, baseHexLength);
    }

    if (topToBottom)
    {
        return `linear-gradient(180deg, ${hexValue} 0%, ${hexValue}00 100%)`;
    }

    return `linear-gradient(0deg, ${hexValue} 0%, ${hexValue}00 100%)`;
};

const GameContent = ({
    className = "",
    hideRoomCode,
}: {
    className?: string;
    hideRoomCode?: boolean;
}) =>
{
    const { events, room, rendered, theme } = useGameContext();
    const { GameContainer } = theme.data.ApplicationSettings;

    const scrollRef = React.useRef<HTMLDivElement>(null);
    const latestRef = React.useRef<HTMLDivElement>(null);

    const [lastNode, setLastNode] = React.useState<HTMLDivElement | null>(null);
    const [scroll, setScroll] = React.useState(false);

    React.useLayoutEffect(() =>
    {
        if (!scroll) return;
        if (!scrollRef.current) return;

        scrollRef.current.scrollTo({
            top: scrollRef.current.scrollHeight,
            behavior: "smooth",
        });

        const stop = setTimeout(() => void setScroll(false), 1000);

        return () => void clearTimeout(stop);
    }, [scroll, lastNode]);

    const { nodes, latest } = events.reduce((current, event, index) =>
    {
        if (index <= rendered)
        {
            current.nodes.push((
                <GameNode
                    key={`${room.id}/${index}/${event.type}`}
                    {...event}
                    eventRef={latestRef}
                />
            ));

            if (latestRef.current !== null) current.latest = latestRef.current;
        }

        return current;
    }, {
        nodes: new Array<React.ReactNode>(),
        latest: null as HTMLDivElement | null,
    });

    if (lastNode !== latest)
    {
        setLastNode(latest);

        const atBottom = (() =>
        {
            if (!lastNode) return true;
            if (!scrollRef.current) return false;

            const { top, height } = lastNode.getBoundingClientRect();
            const { bottom } = scrollRef.current.getBoundingClientRect();
            const center = top + height/2;

            return center < bottom;
        })();

        if (atBottom) setScroll(true);
    }

    return (
        <>
            <div
                id="scroll-region"
                ref={scrollRef}
                className={twMerge(`
                    w-full
                    h-full
                    overflow-y-auto
                    overflow-x-clip
                    overscroll-none
                    py-[0.85rem]
                    flex-[1_1_100%]
                    relative
                `, className)}
            >
                <div
                    className="
                        fixed
                        left-0
                        right-0
                        pointer-events-none
                        -z-[1]
                        bg-center
                        bg-cover
                        -mt-[0.85rem]
                    "
                    style={{
                        backgroundColor: GameContainer.backgroundColor,
                        backgroundImage: GameContainer.backgroundImage
                            ? `url(${GameContainer.backgroundImage})` : undefined,
                        height: scrollRef.current?.clientHeight ?? "100%",
                    }}
                >
                    <div
                        className="absolute w-full left-0 h-14 right-0 top-0"
                        style={{
                            background: gradientBackground(GameContainer.backgroundColor),
                        }}
                    />
                    <div
                        className="absolute w-full left-0 right-0 h-14 bottom-0"
                        style={{
                            background: gradientBackground(GameContainer.transitionBottomColor, false),
                        }}
                    />
                </div>
                {nodes}
            </div>
            {events.map((event, index) => (
                event.type === Type.GAME_START && (
                    <LowerLobbyControls
                        key={`lobby-controls-${room.id}-${index}`}
                        theme={theme}
                        event={event}
                        hideRoomCode={hideRoomCode}
                    />
                )
            ))}

        </>
    );
};

export default GameContent;
