import { ReactComponent as TopSubmissionSliver } from "@/img/chat/top-submission-sliver.svg";
import { ReactComponent as BottomSubmissionSliver } from "@/img/chat/bottom-submission-sliver.svg";
import type { Type } from "@/realize-shared/model/Event";
import StyledText from "@/chat/components/StyledText";
import type { GameNodeProps } from "../GameNodeProps";

const SubmissionMessage = ({
    player,
    theme,
    eventRef,
}: GameNodeProps<Type.PHOTO_SUBMIT>) =>
{
    return (
        <div
            className="mb-2 drop-shadow z-[1]"
            ref={eventRef}
        >
            <TopSubmissionSliver className="text-[#E9E9E9] -mb-[1px] w-full" />
            <div
                className={`
                bg-[#E9E9E9]
                text-[#424242]
                px-4
                py-2
                flex
                items-center justify-center
                text-base
                font-normal
                leading-normal
                font-poppins
                gap-1
                top-0
            `}
            >
                <div>
                    <img
                        src={player.avatar.path}
                        alt="avatar"
                        className="
                            inline
                            rounded-full
                            bg-[#424242]
                            w-6
                            h-6
                            mr-1
                        "
                    />
                    <StyledText
                        theme={theme}
                        text={`*${player.name}* submitted a photo`}
                    />
                </div>
            </div>
            <BottomSubmissionSliver className="text-[#E9E9E9] -mt-[1px] w-full" />
        </div>
    );
};

export default SubmissionMessage;
