import type { Type } from "@/realize-shared/model/Event";
import type { GameNodeProps } from "../GameNodeProps";

const RuleUpdate = (
    _event: GameNodeProps<Type.SETTINGS_CHANGE>,
) =>
{
    return null;
};

export default RuleUpdate;
