import type { Type } from "@/realize-shared/model/Event";
import PlayerStatusMessage from "@/chat/components/PlayerStatusMessage";
import type { GameNodeProps } from "../GameNodeProps";

const PlayerJoinMessage = (
    event: GameNodeProps<Type.PLAYER_JOIN>
) => (
    <PlayerStatusMessage
        {...event}
        text="$PLAYER has joined"
        className="mb-2"
    />
);

export default PlayerJoinMessage;
