import type { ThemePack } from "@/game/ShopItem";
import React from "react";

const StyledText = ({text, theme}: { text: string, theme: ThemePack }): React.ReactNode =>
{
    const regex = /\*(?<word>[^*]+)\*/ug;

    const matches = text.matchAll(regex);

    const result: React.ReactNode[] = [];

    let lastIndex = 0;

    const { SystemMessage } = theme.data.ApplicationSettings.GameContainer;

    for (const match of matches)
    {
        if (match.index > -1)
        {
            const { word } = match.groups as { word: string };

            result.push(text.slice(lastIndex, match.index));

            result.push(
                <b
                    key={word}
                    className="uppercase"
                    style={{
                        color: SystemMessage.accentColor
                    }}
                >
                    {word}
                </b>,
            );

            lastIndex = match.index + match[0].length;
        }
    }

    result.push(text.slice(lastIndex));

    return result;
};

export default StyledText;
