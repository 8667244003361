import DotPattern from "@/web/img/central-dot-pattern.webp";
import Texture01 from "@/web/img/central-texture-01.webp";

export default function CentralBackground()
{
    return (
        <div
            className="absolute w-full h-full z-1 pointer-events-none"
            style={{
                // eslint-disable-next-line @stylistic/max-len
                background: "radial-gradient(100% 100% at 80.08% 0%, rgba(25, 72, 98, 0.40) 0%, rgba(25, 72, 98, 0.00) 100%)"
            }}
        >
            <div
                className="absolute w-full h-full pointer-events-none"
                style={{
                    background: `url(${Texture01}) 73.00000190734863px 73.00000190734863px repeat`,
                    mixBlendMode: "multiply",
                    opacity: 0.12,
                }}
            />
            <div
                className="absolute w-full h-1/2 pointer-events-none"
                style={{
                    background: `url(${DotPattern})`,
                    backgroundRepeat: "repeat-x",
                    backgroundPosition: "top center",
                    opacity: 0.15,
                }}
            />

        </div>
    );
}
