import React from "react";
import type { GameContext, Room } from "../types/Game";

export const useRoom = (room: Room) =>
{
    const [ gameData, dispatchGameData ] = React.useReducer((
        _state: GameContext,
    ): GameContext => ({ ...room.data.read }), room.data.read);

    room.data.mount(dispatchGameData);

    return gameData;
};
