/* eslint-disable max-classes-per-file */

import DefaultSettings from "./DefaultSettings.js";

import type { DBHuman } from "./DBHuman.js";
import { type RawEvent } from "./Event.js";
import type { ObjectID, Settings, Version, Text, RoomID, HumanID, Data, Hash } from "./Types.js";

export const AUTH_HEADER = "X-Authorization";

export const REALIZE_HEADER = "X-RealizeID";

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class EmptyRequest
{
}

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class EmptyResponse
{
}

// ----------
// App Requests
// ----------

export class HelloRequest implements Version
{
    public version: Text = "";

    public status: Text = "";

    public hash: Text = "";

    public commit: Text = "";
}

// ----------
// Room Requests
// ----------

class RoomRequest
{
    public roomID: RoomID = "";
}

export class CreateRoomRequest
{
    public name: Text = "";

    public avatar: Hash = "";
}

export class CreateRoomResponse
{
    public roomID: RoomID = "";
}

export class LeaveRoomRequest extends RoomRequest
{}

export class JoinRoomRequest extends RoomRequest
{
    public name: Text = "";

    public avatar: Hash = "";
}

export class KickPlayerRequest extends RoomRequest
{
    public kickID: HumanID = "";
}

export class GetRoomDataRequest
{
    public current: (RoomID|ObjectID)[] = [];
}

export class GetRoomDataResponse
{
    public data: (RoomID|GetRoomEventsResponse)[] = [];
}

export class StartGameRequest extends RoomRequest
{

}

// ----------
// Player Requests
// ----------

export class UpdateRulesRequest extends RoomRequest
{
    public rules: Settings = {
        ...DefaultSettings
    };
}

export class SubmitHeadlineRequest extends RoomRequest
{
    public headline: Text = "";
}

export class SubmitPhotoRequest extends RoomRequest
{
    public photoData: Data = "";
}

export class SelectWinnerRequest extends RoomRequest
{
    public winnerID: HumanID = "";
}

export class HumanRequest
{
    public humanIDs: HumanID[] = [];
}

export class HumanResponse
{
    public humans: DBHuman[] = [];
}

export class GetEventRequest extends RoomRequest
{
    public lastEvent: ObjectID = "";
}

export class SendChatRequest extends RoomRequest
{
    public message: Text = "";
}

export class GetRoomEventsResponse
{
    public events: RawEvent[] = [];

    public hasMore: boolean = true;
}

export class GetViewerDataRequest
{
    public room: RoomID = "";

    public last: ObjectID = "";
}

export class HelloHeaders
{
    public [REALIZE_HEADER]: string = "";
}

export class RoomList
{
    public rooms: ObjectID[] = [];
}

export class HistoryDataRequest extends GetViewerDataRequest
{
    public room: ObjectID = "";

    public last: ObjectID = "";
}
