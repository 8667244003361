export const PHOTOGRAPH_WIDTH_PX = 1080;

export const PHOTOGRAPH_HEIGHT_PX = 1920;

export const MINIMUM_PLAYERS_FOR_GAME = 2;

export const DEFAULT_MIN_PER_ROUND = 5;

export const MIN_HEADLINE_LENGTH = 3;

export const MIN_NAME_LENGTH = 3;

export const MAX_NAME_LENGTH = 25;

export const CHAT_POLL_TIME_INITIAL = 1000 / 100; // 0.1 seconds

export const CHAT_POLL_TIME_SCALE = 1.05;

export const CHAT_POLL_TIME_MAX = 10 * 1000; // 10 seconds
