import HTTPStatus from "@/realize-shared/HTTPStatus";
import * as Requests from "./Requests";
import * as Environment from "@/data/Environment";
import * as Cache from "@/util/Cache";
import * as Time from "@/realize-shared/model/Time";
import type {
    DBHuman,
} from "@/realize-shared/model/DBHuman";
import { HelloRequest, REALIZE_HEADER, type HelloHeaders } from "@/realize-shared/model/RequestTypes";

let helloState = HTTPStatus.UNINITIATED;

export const hello = async () =>
{
    if (helloState !== HTTPStatus.UNINITIATED) return;

    helloState = HTTPStatus.INITIATED;

    const canConnect = await Requests.sendRequest(
        "POST",
        "app/hello",
        { body: Environment.VERSION }
    )
        .then((response) =>
        {
            helloState = response.status;

            return response.body?.canConnect;
        });

    return Boolean(canConnect);
};

let loginState = HTTPStatus.UNINITIATED;

export const login = async (
    clientID: string,
) =>
{
    if (loginState !== HTTPStatus.UNINITIATED) throw new Error("Login already attempted");

    // @todo: oauth

    const headers: HelloHeaders = {
        [REALIZE_HEADER]: clientID,
    };

    const body: HelloRequest = Environment.VERSION;

    return await Requests.sendRequest(
        "POST",
        "app/login",
        {
            body,
            headers: { ...headers },
        }
    )
        .then((response) =>
        {
            Logger.log("login", response.body?.id);

            loginState = response.status;

            const userId = response.body?.id;

            if (!userId || typeof userId !== "string") throw new Error("Did not receive userId");

            Cache.set("auth", userId, 2 * Time.S_IN_Y);

            return response.body as unknown as DBHuman;
        });
};
