
import type { Round } from "@/game/types/Game";
import HeadlineDisplay from "./HeadlineDisplay";
import Polaroid from "./Polaroid";

const SLOT_1_ANGLE = -5.6145;
const SLOT_2_ANGLE = 1.308;
const SLOT_3_ANGLE = -7.859;
const SLOT_4_ANGLE = -1.587;
const SLOT_5_ANGLE = 3.664;
const SLOT_6_ANGLE = 3.318;

const ROTATE_INDEX = [
    SLOT_1_ANGLE,
    SLOT_2_ANGLE,
    SLOT_3_ANGLE,
    SLOT_4_ANGLE,
    SLOT_5_ANGLE,
    SLOT_6_ANGLE,
];

export default function VotingView({
    round,
}: {
    round: Round;
})
{
    return (
        <div
            className={Wind.style({
                display: "flex",
                flexDirection: "flex-col",
                height: "h-full",
                width: "w-full",
                alignItems: "items-center",
            }).class}
        >
            <HeadlineDisplay
                headline={round.headline ?? ""}
                className={Wind.style({
                    marginTop: "mt-[4.688rem]",
                    marginBottom: "mb-16"
                }).class}
            />
            <div
                className={Wind.style({
                    display: "flex",
                    gap: "gap-14",
                    width: "w-full",
                    justifyContent: "justify-center",
                    alignItems: "items-center",
                }).class}
            >

                {Array.from(round.submissions).map((submission, index) => (
                    <div
                        key={`vote-${submission[0]}-${index}`}
                        className={Wind.style({
                            width: "w-[33.3333%]",
                            display:"flex",
                            alignItems : "items-center",
                            justifyContent: "justify-center",
                        }).class}
                    >
                        <Polaroid
                            imagePath={submission[1]}
                            className={Wind.style({
                                width: "w-[218px]",
                                height: "h-[312px]",
                            }).class}

                            style={{
                            // eslint-disable-next-line @stylistic/max-len
                                boxShadow: "0px 16px 24px 0px rgba(0, 0, 0, 0.14), 0px 6px 30px 0px rgba(0, 0, 0, 0.12), 0px 8px 10px 0px rgba(0, 0, 0, 0.20)",
                                rotate: `${ROTATE_INDEX[index]}deg`,
                            }}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
}
