import { hashCode } from "@/util/Util";
import { MS_IN_S } from "@/realize-shared/model/Time";

let cachePrefix = "";

const cacheMapKeys: Map<string, string> = new Map();

const cacheMapExpiryKeys: Map<string, string> = new Map();

interface Expiry
{
    time: number;
    expiry: number;
}

export const setPrefix = (prefix: string) =>
{
    if (cachePrefix) throw new Error("Prefix already set");
    if (!prefix) throw new Error("Empty prefix");

    cachePrefix = prefix;
};

const getKey = (key: string, prefix: string | undefined) =>
{
    let masked = cacheMapKeys.get(key);

    if (masked) return masked;

    masked = `${prefix ?? cachePrefix}:${hashCode(key)}`;

    cacheMapKeys.set(key, masked);

    return masked;
};

const getValidationKey = (key: string, prefix: string | undefined) =>
{
    let masked = cacheMapExpiryKeys.get(key);

    if (masked) return masked;

    masked = `${prefix ?? cachePrefix}-validation:${hashCode(key)}`;

    cacheMapExpiryKeys.set(key, masked);

    return masked;
};

/**
 * Places item into cache
 *
 * @param key Name of the data to be stored
 * @param value The actual data
 * @param validation Either a max time before removal or a string to compare with
 */
export const set = <T = void>(
    key: string,
    value: T,
    validate?: string | number | null,
    prefix?: string,
) =>
{
    const maskedKey = getKey(key, prefix);
    const expiryKey = getValidationKey(key, prefix);

    if (typeof validate === "number")
    {
        if (validate <= 0) return;

        const expiry: Expiry = {
            "time":   Math.round(Date.now() / MS_IN_S),
            "expiry": Math.round(validate),
        };

        validate = JSON.stringify(expiry);
    }
    else if (typeof validate === "string")
    {
        validate = JSON.stringify(validate);
    }
    else
    {
        validate = "true";
    }

    localStorage.setItem(maskedKey, JSON.stringify(value));
    localStorage.setItem(expiryKey, validate);
};

/**
 * Checks if cache validation string matches supplied value
 */
const validateAgainstString = (cached: string, validation: string) => cached === validation;

/**
 * Checks if cache has expired. If not, renews
 */
const validateAgainstTime = (expiryKey: string, cached: Expiry) =>
{
    const now = Math.round(Date.now() / MS_IN_S);

    if (cached.time + cached.expiry < now) return false;

    const expiry: Expiry = {
        "time":   now,
        "expiry": cached.expiry,
    };

    localStorage.setItem(expiryKey, JSON.stringify(expiry));

    return true;
};

/**
 * Checks if data is in cache
 *
 * @param key The name of the cached value
 * @param validate String to compare to previously set validation data
 */
export const check = (key: string, validate?: string | null, prefix?: string) =>
{
    const expiryKey = getValidationKey(key, prefix);
    const rawExpiry = JSON.parse(localStorage.getItem(expiryKey) ?? "false") as unknown;

    const okay = (() =>
    {
        switch (typeof rawExpiry)
        {
            case "boolean": return rawExpiry;
            case "string": return validateAgainstString(rawExpiry, validate ?? rawExpiry);
            case "object": return validateAgainstTime(expiryKey, rawExpiry as Expiry);
            default: return false;
        }
    })();

    if (!okay) remove(key);

    return okay;
};


/**
 * Removes cached data from localstorage and memory
 *
 * @param key The name of the cached value
 */
export const remove = (key: string, prefix?: string) =>
{
    const maskedKey = getKey(key, prefix);
    const expiryKey = getValidationKey(key, prefix);

    cacheMapKeys.delete(key);
    cacheMapExpiryKeys.delete(key);

    localStorage.removeItem(maskedKey);
    localStorage.removeItem(expiryKey);
};

/**
 * Retrieves cached data from localstorage or memory
 *
 * @param key The name of the cached value
 * @param validate String to compare to previously set validation data
 */
export const get = <T = void>(
    key: string,
    validate?: string | null,
    prefix?: string,
): T | undefined =>
{
    if (!check(key, validate)) return;

    const maskedKey = getKey(key, prefix);
    const value = localStorage.getItem(maskedKey) ?? "null";

    return JSON.parse(value) as T;
};
