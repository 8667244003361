import React from "react";
import type { GameContext } from "./types/Game";

export const ReactGameContext = React.createContext<GameContext|null>(null);

export const useGameContext = () =>
{
    const context = React.useContext(ReactGameContext);

    AssertIsset(context, "useGameContext must be used within a ReactGameContext");

    return context;
};
