const CDN_BASE = "https://realize-datastore.nyc3.digitaloceanspaces.com/static/themes/";

function _processTheme(xmlDoc: Document)
{
    const ApplicationSettings = xmlDoc.querySelector("ApplicationSettings");
    const TopSection = xmlDoc.querySelector("ApplicationSettings TopSection");
    const Clock = xmlDoc.querySelector("ApplicationSettings TopSection Clock");
    const PlayerCounter = xmlDoc.querySelector("ApplicationSettings TopSection PlayerCounter");
    const LobbyControls = xmlDoc.querySelector("ApplicationSettings TopSection LobbyControls");
    const StartButton = xmlDoc.querySelector("ApplicationSettings TopSection LobbyControls StartButton");
    const WaitStatus = xmlDoc.querySelector("ApplicationSettings TopSection LobbyControls WaitStatus");

    const GameContainer = xmlDoc.querySelector("ApplicationSettings GameContainer");

    const ChatMessage = xmlDoc.querySelector("ApplicationSettings GameContainer ChatMessage");
    const IncomingMessage = xmlDoc.querySelector("ApplicationSettings GameContainer ChatMessage IncomingMessage");
    const OutgoingMessage = xmlDoc.querySelector("ApplicationSettings GameContainer ChatMessage OutgoingMessage");

    const HeadlineStatusHeading = xmlDoc.querySelector("ApplicationSettings GameContainer HeadlineStatus Heading");
    const HeadlineStatusStatus = xmlDoc.querySelector("ApplicationSettings GameContainer HeadlineStatus Status");

    const CreateHeadlineEventEditor
        = xmlDoc.querySelector("ApplicationSettings GameContainer CreateHeadlineEventEditor");

    const CreateHeadlineEventEditorHeading
        = xmlDoc.querySelector("ApplicationSettings GameContainer CreateHeadlineEventEditor Heading");

    const CreateHeadlineEventEditorCTA
        = xmlDoc.querySelector("ApplicationSettings GameContainer CreateHeadlineEventEditor CallToAction");

    const PhotoSubmissionHeading = xmlDoc.querySelector("ApplicationSettings GameContainer PhotoSubmissions Heading");
    const PhotoSubmissionPhotos = xmlDoc.querySelector("ApplicationSettings GameContainer PhotoSubmissions Photos");

    const ScoreBoardEventWinner = xmlDoc.querySelector("ApplicationSettings GameContainer ScoreboardEvent Winner");
    const ScoreBoardEventHeading = xmlDoc.querySelector("ApplicationSettings GameContainer ScoreboardEvent Heading");
    const ScoreBoardEventScore = xmlDoc.querySelector("ApplicationSettings GameContainer ScoreboardEvent Scoreboard");

    const RoundTag = xmlDoc.querySelector("ApplicationSettings GameContainer RoundTag");

    const SystemMessage = xmlDoc.querySelector("ApplicationSettings GameContainer SystemMessage");

    const MessageSection = xmlDoc.querySelector("ApplicationSettings MessageSection");

    Logger.log(GameContainer);


    AssertIsset(GameContainer, "GameContainer not found");

    const calculatePath = (elem: Element, attributeName: string, isOptional: boolean = false) =>
    {
        const fileName = elem.getAttribute(attributeName);

        if (isOptional && !fileName)
        {
            return undefined;
        }

        AssertIsset(fileName, `Attribute ${attributeName} not found`);

        return `${CDN_BASE}${fileName}`;
    };

    // Convert the XML to JSON
    const themeSchema = {
        ApplicationSettings: {
            themeStyle: ApplicationSettings?.getAttribute("themeStyle") as "light" | "dark",
            TopSection: {
                leaveIconColor: TopSection?.getAttribute("leaveIconColor") as string,
                backgroundColor: TopSection?.getAttribute("backgroundColor") as string,
                Clock: {
                    iconColor: Clock?.getAttribute("iconColor") as string,
                    textColor: Clock?.getAttribute("textColor") as string,
                    backgroundColor: Clock?.getAttribute("backgroundColor") as string,
                },
                PlayerCounter: {
                    textColor: PlayerCounter?.getAttribute("textColor") as string,
                    backgroundColor: PlayerCounter?.getAttribute("backgroundColor") as string,
                    activeBackgroundColor: PlayerCounter?.getAttribute("activeBackgroundColor") as string,
                    activeTextColor: PlayerCounter?.getAttribute("activeTextColor") as string,
                },
                LobbyControls: {
                    backgroundColor: LobbyControls?.getAttribute("backgroundColor") as string,
                    textColor: LobbyControls?.getAttribute("textColor") as string,
                    StartButton: {
                        textColor: StartButton?.getAttribute("textColor") as string,
                        backgroundColor: StartButton?.getAttribute("backgroundColor") as string,
                        disabledTextColor: StartButton?.getAttribute("disabledTextColor") as string,
                        disabledBackgroundColor: StartButton?.getAttribute("disabledBackgroundColor") as string,
                    },
                    WaitStatus: {
                        textColor: WaitStatus?.getAttribute("textColor") as string,
                        backgroundColor: WaitStatus?.getAttribute("backgroundColor") as string,
                    }
                }
            },
            GameContainer: {
                transitionTopColor: GameContainer.getAttribute("transitionTopColor") as string,
                transitionBottomColor: GameContainer.getAttribute("transitionBottomColor") as string,
                backgroundColor: GameContainer.getAttribute("backgroundColor") as string,
                backgroundImage: calculatePath(GameContainer, "backgroundImage", true),
                ChatMessage: {
                    parentVerticalPadding: ChatMessage?.getAttribute("parentVerticalPadding") as string,
                    IncomingMessage: {
                        textColor: IncomingMessage?.getAttribute("textColor") as string,
                        backgroundColor: IncomingMessage?.getAttribute("backgroundColor") as string,
                        accentColor: IncomingMessage?.getAttribute("accentColor") as string,
                    },
                    OutgoingMessage: {
                        textColor: OutgoingMessage?.getAttribute("textColor") as string,
                        backgroundColor: OutgoingMessage?.getAttribute("backgroundColor") as string,
                    }
                },
                SystemMessage: {
                    textColor: SystemMessage?.getAttribute("textColor") as string,
                    backgroundColor: SystemMessage?.getAttribute("backgroundColor") as string,
                    accentColor: SystemMessage?.getAttribute("accentColor") as string,
                    verticalPaddingTop: SystemMessage?.getAttribute("verticalPaddingTop") as string,
                    verticalPaddingBottom: SystemMessage?.getAttribute("verticalPaddingBottom") as string,
                    parentVerticalPadding: SystemMessage?.getAttribute("parentVerticalPadding") as string,
                },
                HeadlineStatus: {
                    Heading: {
                        textColor: HeadlineStatusHeading?.getAttribute("textColor") as string,
                        accentColor: HeadlineStatusHeading?.getAttribute("accentColor") as string,
                        backgroundColor: HeadlineStatusHeading?.getAttribute("backgroundColor") as string,
                        verticalPaddingTop: HeadlineStatusHeading?.getAttribute("verticalPaddingTop") as string,
                        verticalPaddingBottom: HeadlineStatusHeading?.getAttribute("verticalPaddingBottom") as string,
                    },
                    Status: {
                        textColor: HeadlineStatusStatus?.getAttribute("textColor") as string,
                        backgroundColor: HeadlineStatusStatus?.getAttribute("backgroundColor") as string,
                        verticalPaddingTop: HeadlineStatusStatus?.getAttribute("verticalPaddingTop") as string,
                        verticalPaddingBottom: HeadlineStatusStatus?.getAttribute("verticalPaddingBottom") as string,
                    }
                },
                CreateHeadlineEventEditor: {
                    marginBottom: CreateHeadlineEventEditor?.getAttribute("marginBottom") as string,
                    Heading: {
                        textColor: CreateHeadlineEventEditorHeading?.getAttribute("textColor") as string,
                        accentColor: CreateHeadlineEventEditorHeading?.getAttribute("accentColor") as string,
                        backgroundColor: CreateHeadlineEventEditorHeading?.getAttribute("backgroundColor") as string,
                        verticalPaddingTop: CreateHeadlineEventEditorHeading
                            ?.getAttribute("verticalPaddingTop") as string,
                        verticalPaddingBottom: CreateHeadlineEventEditorHeading
                            ?.getAttribute("verticalPaddingBottom") as string,
                    },
                    CallToAction: {
                        textColor: CreateHeadlineEventEditorCTA?.getAttribute("textColor") as string,
                        backgroundColor: CreateHeadlineEventEditorCTA?.getAttribute("backgroundColor") as string,
                        verticalPaddingTop: CreateHeadlineEventEditorCTA
                            ?.getAttribute("verticalPaddingTop") as string,
                        verticalPaddingBottom: CreateHeadlineEventEditorCTA
                            ?.getAttribute("verticalPaddingBottom") as string,
                    }
                },
                PhotoSubmission: {
                    Heading: {
                        textColor: PhotoSubmissionHeading?.getAttribute("textColor") as string,
                        accentColor: PhotoSubmissionHeading?.getAttribute("accentColor") as string,
                        backgroundColor: PhotoSubmissionHeading?.getAttribute("backgroundColor") as string,
                        verticalPaddingTop: PhotoSubmissionHeading?.getAttribute("verticalPaddingTop") as string,
                        verticalPaddingBottom: PhotoSubmissionHeading?.getAttribute("verticalPaddingBottom") as string,
                        iconColor: PhotoSubmissionHeading?.getAttribute("iconColor") as string,
                        iconBackgroundColor: PhotoSubmissionHeading?.getAttribute("iconBackgroundColor") as string,
                    },
                    Photos: {
                        textColor: PhotoSubmissionPhotos?.getAttribute("textColor") as string,
                        backgroundColor: PhotoSubmissionPhotos?.getAttribute("backgroundColor") as string,
                        verticalPaddingTop: PhotoSubmissionPhotos?.getAttribute("verticalPaddingTop") as string,
                        verticalPaddingBottom: PhotoSubmissionPhotos?.getAttribute("verticalPaddingBottom") as string,
                    }
                },
                ScoreboardEvent: {
                    Winner:{
                        textColor: ScoreBoardEventWinner?.getAttribute("textColor") as string,
                        accentColor: ScoreBoardEventWinner?.getAttribute("accentColor") as string,
                        backgroundColor: ScoreBoardEventWinner?.getAttribute("backgroundColor") as string,
                        verticalPaddingTop: ScoreBoardEventWinner?.getAttribute("verticalPaddingTop") as string,
                        verticalPaddingBottom: ScoreBoardEventWinner?.getAttribute("verticalPaddingBottom") as string,
                    },
                    Heading: {
                        textColor: ScoreBoardEventHeading?.getAttribute("textColor") as string,
                        backgroundColor: ScoreBoardEventHeading?.getAttribute("backgroundColor") as string,
                        verticalPaddingTop: ScoreBoardEventHeading?.getAttribute("verticalPaddingTop") as string,
                        verticalPaddingBottom: ScoreBoardEventHeading?.getAttribute("verticalPaddingBottom") as string,
                    },
                    Scoreboard: {
                        textColor: ScoreBoardEventScore?.getAttribute("textColor") as string,
                        accentColor: ScoreBoardEventScore?.getAttribute("accentColor") as string,
                        backgroundColor: ScoreBoardEventScore?.getAttribute("backgroundColor") as string,
                        verticalPaddingTop: ScoreBoardEventScore?.getAttribute("verticalPaddingTop") as string,
                        verticalPaddingBottom: ScoreBoardEventScore?.getAttribute("verticalPaddingBottom") as string,
                    }
                },
                RoundTag: {
                    textColor: RoundTag?.getAttribute("textColor") as string,
                    backgroundColor: RoundTag?.getAttribute("backgroundColor") as string,
                    verticalPaddingTop: RoundTag?.getAttribute("verticalPaddingTop") as string,
                    verticalPaddingBottom: RoundTag?.getAttribute("verticalPaddingBottom") as string,
                    parentVerticalPaddingTop: RoundTag?.getAttribute("parentVerticalPaddingTop") as string,
                    parentVerticalPaddingBottom: RoundTag?.getAttribute("parentVerticalPaddingBottom") as string,
                }
            },
            MessageSection: {
                backgroundColor: MessageSection?.getAttribute("backgroundColor") as string,
                textColor: MessageSection?.getAttribute("textColor") as string,
                chatInputBackgroundColor: MessageSection?.getAttribute("chatInputBackgroundColor") as string,
                chatInputTextColor: MessageSection?.getAttribute("chatInputTextColor") as string,
                chatActiveBackgroundColor: MessageSection?.getAttribute("chatActiveBackgroundColor") as string,
                chatActiveTextColor: MessageSection?.getAttribute("chatActiveTextColor") as string,
                sendIconColor: MessageSection?.getAttribute("sendIconColor") as string,
            }
        }
    };

    return themeSchema;
}

export const processTheme = async (cdn_path: string) =>
{
    const xmlData = await fetch(cdn_path, {
        cache: "no-store",
    }).then(response => response.text());

    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlData, "text/xml");

    return _processTheme(xmlDoc);
};

export type ProccessThemeSchema = ReturnType<typeof _processTheme>;
