import React from "react";
import ExpandButton from "@/chat/components/ExpandButton";
import ChatPolaroid from "@/chat/components/ChatPolaroid";

import {ReactComponent as ScoreboardTopSliver} from "@/img/chat/scoreboard-top-sliver.svg";
import {ReactComponent as ScoreboardBottomSliver} from "@/img/chat/scoreboard-bottom-sliver.svg";
import {ReactComponent as ScoreboardListBottom} from "@/img/chat/scoreboard-bottom-list.svg";
import {ReactComponent as ScoreboardPoints} from "@/img/chat/scoreboard-points.svg";
import type { Type } from "@/realize-shared/model/Event";
import Ordinal from "@/util/Ordinal";
import type { GameNodeProps } from "../GameNodeProps";
import { useGameContext } from "@/game/GameContext";

const RoundWinner = ({
    winner,
    scoreboard,
    theme,
    photo,
    eventRef,
}: GameNodeProps<Type.VOTE_RESULT>) =>
{
    const [expanded, setExpanded] = React.useState(false);
    const toggleExpand = () => void setExpanded(!expanded);

    const {rules} = useGameContext();

    const {ScoreboardEvent} = theme.data.ApplicationSettings.GameContainer;

    const {Heading, Scoreboard, Winner} = ScoreboardEvent;

    return (
        <div
            className={`mt-4 ${expanded ? "mb-2" : "mb-4"}`}
            ref={eventRef}
        >
            <ScoreboardTopSliver
                className="-mt-1 w-full"
                style={{
                    color: Winner.backgroundColor,
                    translate: "0 0.5px"
                }}
            />
            <div
                className="text-white"
                style={{
                    backgroundColor: Winner.backgroundColor,
                    color: Winner.textColor
                }}
            >
                <div className="flex items-center justify-between pb-4 px-14 gap-5">
                    <ChatPolaroid
                        className="w-32 mt-4 p-1"
                        photo={photo}
                    />
                    <div>
                        <h1 className="font-poppins text-2xl font-bold mb-1 leading-[120%]">
                            <span
                                style={{
                                    color: Winner.accentColor
                                }}
                            >
                                {winner.name}
                            </span>
                            <br />
                            {" was chosen!"}
                        </h1>
                        <div className="font-poppins text-base relative inline-block">
                            <ScoreboardPoints className="text-poppy-yellow" />
                            <span className="absolute inset-0 flex justify-center items-center">
                                +1 Point
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <ScoreboardTopSliver
                    className="-mt-2 w-full -mb-[1px]"
                    style={{
                        color: Heading.backgroundColor,
                    }}
                />
                <div
                    className="py-2 text-center relative font-bold"
                    style={{
                        backgroundColor: Heading.backgroundColor,
                        color: Heading.textColor
                    }}
                >
                Scoreboard
                    <ExpandButton
                        toggleExpand={toggleExpand}
                        expanded={expanded}
                        theme={theme}
                    />
                </div>
                <ScoreboardBottomSliver
                    className="-mb-2 w-full relative -mt-[1px]"
                    style={{
                        color: Heading.backgroundColor
                    }}
                />
            </div>
            <div
                className={`overflow-hidden ${expanded ? "h-fit pt-4" : "h-0"}`}
                style={{
                    backgroundColor: Scoreboard.backgroundColor,
                    color: Scoreboard.textColor
                }}
            >
                {scoreboard.map(({ player, score }, index) => (
                    <div
                        key={index}
                        className="
                            flex
                            gap-2
                            items-center
                            justify-center
                            px-4
                            py-2
                            font-poppins
                            w-full
                        "
                    >
                        <span
                            className="text-base text-right font-bold flex-[0_0_3rem]"
                            style={{
                                color: Scoreboard.accentColor
                            }}
                        >
                            {
                                // FIXME: this does not account for ties
                                (index+1)+Ordinal.from(index+1)
                            }
                        </span>
                        <h1 className="text-left font-bold flex-[1_1_100%]">
                            {player.name}
                        </h1>
                        <span className="font-bold flex-[0_0_4rem]">
                            <span
                                style={{
                                    color: Scoreboard.accentColor

                                }}
                            >
                                {score}
                            </span>
                            {` / ${rules.pointsToWin} `}
                            <span className="font-normal">Pts</span>
                        </span>
                    </div>
                ))}
            </div>
            {expanded && (
                <ScoreboardListBottom
                    className="w-full relative -top-[1px]"
                    style={{
                        color: Scoreboard.backgroundColor
                    }}
                />
            )}
        </div>
    );
};

export default RoundWinner;
