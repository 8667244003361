import StatusMessage from "@/chat/components/StatusMessage";
import StyledText from "@/chat/components/StyledText";
import type { Type } from "@/realize-shared/model/Event";
import type { GameNodeProps } from "../GameNodeProps";

const PlayerStatusMessage = ({
    text, ...props
}: {
    text: string;
    className?: string;
} & GameNodeProps<Type.PLAYER_JOIN | Type.PLAYER_LEAVE>) =>
{
    const {player: {avatar, name}, theme} = props;

    return (
        <StatusMessage {...props} >
            <img
                src={avatar.path}
                alt={name}
                className="rounded-full bg-[#424242] h-6"
            />
            <StyledText
                theme={theme}
                text={text.replace("$PLAYER", `*${name}*`)}
            />
        </StatusMessage>
    );
};

export default PlayerStatusMessage;

