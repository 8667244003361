import React from "react";
import { twMerge } from "tailwind-merge";
import type { GameNodeProps } from "../GameNodeProps";
import type { Type } from "@/realize-shared/model/Event";

const StatusMessage = ({
    children,
    theme,
    className,
}: React.PropsWithChildren<{
    className?: string;
} & GameNodeProps<Type>>) =>
{
    const {SystemMessage} = theme.data.ApplicationSettings.GameContainer;

    return (
        <div
            className={twMerge("mt-2 drop-shadow z-[1]", className)}
        >
            {/* <TopSubmissionSliver
                className="text-[#E9E9E9] -mb-[1px]"
                style={{
                    "transform": "scale(-1, 1)",
                }}
            /> */}
            <div
                className={`
                px-4
                py-2
                flex
                items-center
                justify-center
                text-base
                font-normal
                leading-normal
                font-poppins
                gap-1
                top-0
            `}
                style={{
                    backgroundColor: SystemMessage.backgroundColor,
                    color: SystemMessage.textColor,
                }}
            >
                {children}
            </div>
            {/* <BottomSubmissionSliver
                className="text-[#E9E9E9] -mt-[1px]"
                style={{
                    "transform": "scale(-1, 1)",
                }}
            /> */}
        </div>
    );
};

export default StatusMessage;
