import StyledText from "@/chat/components/StyledText";
import Magazine from "@/components/Magazine";
import { useGameContext } from "@/game/GameContext";
import type { Player } from "@/game/types/Game";
import type { Resource } from "@/realize-shared/model/Types";

export default function MagazinePreview({
    winner,
    photo,
    headline,
    timestamp,
}: {
    winner: Player;
    photo: Resource;
    headline: string;
    timestamp: number;
})
{
    const {round, theme} = useGameContext();

    return (
        <div
            className={Wind.style({
                backgroundColor: "bg-parchment",
                color: "text-dark-blue",
                padding: "p-4",
                borderTopRadius: "rounded-t-[3.5rem]",
                borderBottomRadius: "rounded-b-lg",
                marginTop: "mt-[5.5rem]",
            }).class}
        >
            <div
                className={Wind.style({
                    backgroundColor: "bg-[#FFEAA1]",
                    height: "h-[8.5rem]",
                    borderRadius: "rounded-[3rem]",
                    position: "relative",
                    marginX: "mx-4",
                    marginBottom: "mb-2",
                }).class}
            >
                <div
                    className={Wind.style({
                        position: "absolute",
                        height: "h-[16.75rem]",
                        borderBottomRadius: "rounded-b-[3rem]",
                        width: "w-full",
                        bottom: "bottom-0",
                        overflow: "overflow-clip",
                        display: "flex",
                        justifyContent: "justify-center",
                    }).class}
                >
                    {round && (
                        <Magazine
                            key={`magazine-${round.number}`}
                            issueNumber={1}
                            photoPath={photo}
                            username={winner.name}
                            headline={headline}
                            timestamp={timestamp}
                            className={Wind.style({
                                height: "h-[16.875rem]",
                                width: "w-auto",
                                transformRotate: "rotate-1",
                                transformTranslateY: "translate-y-6",
                                transformTranslateX: "translate-x-[3%]",
                            }).class}
                            isWinner={true}
                            style={{
                                // eslint-disable-next-line @stylistic/max-len
                                boxShadow: "0px 8px 10px 0px rgba(0, 0, 0, 0.14), 0px 3px 14px 0px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.20)",
                                aspectRatio: "192.825/273.408"
                            }}
                        />
                    )}
                </div>
            </div>
            <span
                className={Wind.style({
                    fontFamily: "font-poppins",
                    color: "text-dark-blue",
                    fontSize: "text-base",
                    fontWeight: "font-medium",
                    lineHeight: "leading-6",
                    textAlign: "text-left",
                    paddingY: "py-2",
                    display: "inline-block",
                    "::first-letter": {
                        textTransform: "first-letter:uppercase",
                    }
                }).class}
            >
                <StyledText
                    text={headline || "Lorem ipsum dolor sit *amet*, consectetur *adipiscing* elit."}
                    theme={theme}
                />
            </span>
            <div
                className={Wind.style({
                    display: "flex",
                    alignItems: "items-center",
                    gap: "gap-[0.625rem]",
                }).class}
            >
                <img
                    src={winner.avatar.path}
                    className={Wind.style({
                        width: "w-8",
                        height: "h-8",
                        borderRadius: "rounded-full",
                    }).class}
                />
                <div
                    className={Wind.style({
                        display: "flex",
                        flexDirection: "flex-col",

                    }).class}
                >
                    <h1
                        className={Wind.style({
                            fontFamily: "font-poppins",
                            color: "text-dark-blue",
                            fontSize: "text-base",
                            fontWeight: "font-bold",
                            lineHeight: "leading-6",
                            textAlign: "text-left",
                        }).class}
                    >
                        {winner.name}
                    </h1>
                    <p
                        className={Wind.style({
                            fontFamily: "font-poppins",
                            color: "text-dark-blue",
                            fontSize: "text-sm",
                            fontWeight: "font-medium",
                            lineHeight: "leading-6",
                            textAlign: "text-left",
                            marginTop: "-mt-1"
                        }).class}
                    >
                        Last round Winner
                    </p>
                </div>
            </div>
        </div>
    );
}
