import { useEffect, useMemo } from "react";
import GameViewer from "./GameViewer";
import { ReactGameContext } from "@/game/GameContext";
import type { Room } from "@/game/types/Game";
import { Type } from "@/realize-shared/model/Event";
import SendViewGameReq from "@/api/ViewerRequests";
import { buildRoom } from "@/game/events/RoomManager";
import type { ProcessedEvent } from "@/game/events/ProcessedEvent";
import { useRoom } from "@/game/events/useRoom";
import LandingPage from "./pages/LandingPage";
import {RouterProvider, createBrowserRouter, useParams, useNavigate } from "react-router-dom";

export default function RealizeViewer ()
{
    const humanID = "placeholder";

    const router = createBrowserRouter([
        {
            path: "/",
            element: (<LandingPage />)
        }, {
            path: "/:roomID",
            element: (
                <Focused
                    humanID={humanID}
                />
            )
        }
    ]);

    return (
        <RouterProvider router={router} />
    );
}

const Focused = ({ humanID }: {
    humanID: string
}) =>
{
    // Get the room ID from the router params
    const data = useParams<{ roomID: string }>();
    const roomID = data.roomID?.toUpperCase().replaceAll(/[^A-Z]/ug, "");
    const navigate = useNavigate();

    Logger.log("ROOM ID FROM URL PARAMS:", roomID);

    AssertIsset(roomID, "Room ID is not set");

    const room = useMemo<Room>(() =>
    {
        Logger.log("BUILDING ROOM WITH ID:", roomID);

        return buildRoom(roomID, humanID, false);
    }, [humanID, roomID]);

    const gameData = useRoom(room);

    useEffect(() =>
    {
        Logger.log("USE EFFECT TRIGGERED WITH ROOMID:", roomID);

        if (!roomID)
        {
            Logger.log("NO ROOM ID FOUND, EXITING USE EFFECT");

            return;
        }

        const getData = () =>
        {
            Logger.log("Polling for new data");

            Logger.log("Game Data", gameData.events.length);

            const events = gameData.events;
            const lastEvent = events[events.length - 1] as ProcessedEvent<Type> | undefined;
            const lastEventID = lastEvent ? lastEvent.id : null;

            SendViewGameReq(roomID, lastEventID)
                .then(res =>
                {
                    Logger.log("REQUEST SUCCESSFUL:", res);

                    const incomingData = res.events;

                    if (incomingData.length <= 0) return;

                    room.data.receiveData(incomingData);
                })
                .catch((err) =>
                {
                    Logger.log("REQUEST FAILED", err);

                    return;
                });
        };

        const interval = setInterval(getData, 1000);

        //getData().catch((e) => void Logger.error(e));

        return () => void clearInterval(interval);
    }, [roomID, room, gameData.events, navigate]);

    return (
        <ReactGameContext.Provider
            value={gameData}
        >
            <GameViewer />
        </ReactGameContext.Provider>
    );
};
