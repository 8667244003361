import * as Time from "@/realize-shared/model/Time";
import * as DataRequests from "@/api/DataRequests";
import * as Types from "@/realize-shared/Types";
import * as Cache from "@/util/Cache";
import type { DBIconPack } from "@/realize-shared/model/DBIconPack";
import type { Hash } from "@/realize-shared/model/Types";
import type { UserIcon } from "../realize-shared/model/UserIcon";
import { toIconPack, type IconPack } from "./ShopItem";

const loadJustABunchOfImages = async (images: string[]) =>
{
    for await (const path of images)
    {
        await fetch(path).catch(Logger.warn);
    }
};

export class Icons
{
    static #instance?: Icons;

    #loaded: Types.MaybePromise<boolean> = false;

    #icons?: Map<Hash, UserIcon | null>;

    #packs?: readonly IconPack[];

    private constructor ()
    {
        // nothing
    }

    private process (raw: DBIconPack[])
    {
        this.#packs = raw.map(pack => toIconPack(pack));

        this.savePacks();
    }

    private savePacks()
    {
        if (!this.#packs) return;

        const { owned, locked } = this.#packs.reduce((sorted, pack) =>
        {
            const icons = pack.icons.flatMap(i => i.icons);

            ((pack.locked)? sorted.locked: sorted.owned).push(...icons);

            return sorted;
        }, { owned: new Array<UserIcon>(), locked: new Array<UserIcon>() });

        AssertTrue(owned.length > 0, "No icons owned");

        const icons = [ ...owned, ...locked ];

        loadJustABunchOfImages(icons.map(({path}) => path)).catch(Logger.warn);

        this.#icons = new Map(icons.map(icon => [icon.hash, icon]));
    }

    private async download ()
    {
        let data;

        do
        {
            try
            {
                data = await DataRequests.getIconPacks();
            }
            catch
            {
                Logger.warn("Could not fetch icons. Trying again.");

                await new Promise((c) => void setTimeout(c, Time.MS_IN_S));
            }
        } while (!data);

        return data.packs;
    }

    private async retrieve ()
    {
        const name = "icon-dataset";
        const hours = 6;

        const cached = Cache.get<DBIconPack[]>(name);

        if (cached) return cached;

        const data = await this.download();

        Cache.set(name, data, Time.S_IN_H * hours);

        return data;
    }

    private async load ()
    {
        if (await this.#loaded) return;

        if (this.#loaded === false)
        {
            this.#loaded = this.retrieve()
                .then((data) => void this.process(data))
                .then(() => true);
        }

        await this.#loaded;
    }

    public static async load ()
    {
        if (!Icons.#instance)
        {
            Icons.#instance = new Icons();
        }

        await Icons.#instance.load();
    }

    private static get instance ()
    {
        AssertIsset(Icons.#instance, "Loading not initialized");

        return Icons.#instance;
    }

    public static get packs ()
    {
        AssertIsset(Icons.instance.#packs, "Loading not completed");

        return Icons.instance.#packs;
    }

    public static get owned ()
    {
        AssertIsset(Icons.instance.#packs, "Loading not completed");

        return Icons.instance.#packs.filter((pack) => !pack.locked)
            .flatMap(({icons}) => icons)
            .flatMap(({icons}) => icons);
    }

    public static get(hash: Hash)
    {
        AssertIsset(Icons.instance.#icons, "Loading not completed");

        const icon = Icons.instance.#icons.get(hash);

        AssertIsset(icon, "Icon not found");

        return icon;
    }

    public static setOwnership(product_id: string, owned: boolean)
    {
        if (Icons.#instance) Icons.#instance.setOwnership(product_id, owned);
    }

    private setOwnership(product_id: string, owned: boolean)
    {
        if (!this.#packs) return;

        const pack = this.#packs.find(pack => pack.product_id === product_id);

        AssertIsset(pack, "Pack not found");
        pack.locked = !owned;

        const index = this.#packs.indexOf(pack);

        this.#packs = [
            ...this.#packs.slice(0, index),
            pack,
            ...this.#packs.slice(index + 1),
        ];

        this.savePacks();
    }
}
