/**
 * Possible game-phases. Synched between server and client
 *
 * LOBBY:
 *     Page where we are waiting for new players to join and we can set rules
 *
 * HEADLINE_CREATION:
 *     Page where editor defines a prompt for the round
 *
 * PHOTO_SUBMISSION:
 *     Page where players can submit a photo and the editor waits for submissions
 *
 * VOTING:
 *     Page where the editor chooses the winner of the round
 *
 * CLOSED:
 *     When the game is won
 */

const enum Phase
{
    LOBBY = 1,

    HEADLINE_CREATION = 2,

    PHOTO_SUBMISSION = 3,

    VOTING = 4,

    CLOSED = 5,
}

export default Phase;
