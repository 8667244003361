import { toThemePack, type ThemePack } from "@/game/ShopItem";
import * as Types from "@/realize-shared/Types";
import * as Time from "@/realize-shared/model/Time";
import * as DataRequests from "@/api/DataRequests";
import type { DBThemePack } from "@/realize-shared/model/DBThemePack";
import * as Cache from "@/util/Cache";

export class Themes
{
    static #instance?: Themes;

    #loaded: Types.MaybePromise<boolean> = false;

    #owned: readonly ThemePack[] = [];

    #theme_packs?: Map<string, ThemePack | null>;

    #themes: ThemePack[] = [];

    private constructor ()
    {
        // nothing
    }

    private async process (raw: DBThemePack[])
    {
        // Process the themes
        for (const pack of raw)
        {
            // Check if the theme is cached
            const cached = Cache.get<ThemePack>(`theme-${pack.slug}`, pack.hash);

            if (!cached)
            {
                const theme = await toThemePack(pack);

                Cache.set(`theme-${theme.slug}`, theme, pack.hash);

                this.#themes.push(theme);
            }
            else
            {
                this.#themes.push(cached);
            }
        }

        this.#owned = this.#themes.filter(theme => !theme.locked);

        // Parse the XML file associated with the theme
        this.#theme_packs = new Map(this.#themes.map(theme => [theme.slug, theme]));

        Logger.log("Owned Themes", this.#owned);
    }

    private async download ()
    {
        let data;

        do
        {
            try
            {
                data = await DataRequests.getThemePacks();
            }
            catch
            {
                Logger.warn("Could not fetch themes. Trying again.");

                await new Promise((c) => void setTimeout(c, Time.MS_IN_S));
            }
        } while (!data);

        return data.packs;
    }

    private async retrieve ()
    {
        const name = "theme-dataset";
        const hours = 6;

        const cached = Cache.get<DBThemePack[]>(name);

        if (cached) return cached;

        const data = await this.download();

        Cache.set(name, data, Time.S_IN_H * hours);

        return data;
    }

    private async load ()
    {
        if (await this.#loaded) return;

        if (this.#loaded === false)
        {
            const data = await this.retrieve();

            await this.process(data);
            this.#loaded = true;
        }

        await this.#loaded;
    }

    public static async load ()
    {
        if (!Themes.#instance)
        {
            Themes.#instance = new Themes();
        }

        await Themes.#instance.load();
    }

    private static get instance ()
    {
        AssertIsset(this.#instance, "Loading not initialized");

        return this.#instance;
    }

    public static get themes ()
    {
        AssertIsset(this.instance.#themes, "Loading not completed");

        return this.instance.#themes;
    }

    public static get default (): ThemePack
    {
        return this.get("theme-default-theme");
    }

    public static get owned (): readonly ThemePack[]
    {
        AssertIsset(this.instance.#owned, "Loading not completed");

        return this.instance.#owned;
    }

    public static get(slug: string)
    {
        AssertIsset(this.instance.#theme_packs, "Loading not completed");

        const theme = this.instance.#theme_packs.get(slug);

        if (!theme) throw new Error(`Theme "${slug}" not found`);

        return theme;
    }
}
