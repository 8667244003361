// Days

export const D_IN_Y = 365;

export const D_IN_Q = 91;

export const D_IN_M = 30;

export const D_IN_W = 7;

// Hours

export const H_IN_D = 24;

export const H_IN_Y = H_IN_D * D_IN_Y;

// Minutes

export const M_IN_H = 60;

export const M_IN_D = M_IN_H * H_IN_D;

export const M_IN_Y = M_IN_D * D_IN_Y;

// Seconds

export const S_IN_M = 60;

export const S_IN_H = S_IN_M * M_IN_H;

export const S_IN_D = S_IN_H * H_IN_D;

export const S_IN_Y = S_IN_D * D_IN_Y;

// Milliseconds

export const MS_IN_S = 1000;

export const MS_IN_M = MS_IN_S * S_IN_M;

export const MS_IN_H = MS_IN_M * M_IN_H;

export const MS_IN_D = MS_IN_H * H_IN_D;

export const MS_IN_Y = MS_IN_D * D_IN_Y;
