import StyledText from "@/chat/components/StyledText";
import type { ThemePack } from "@/game/ShopItem";

const SystemMessage = ({
    message,
    theme,
}: {
    message: string;
    theme: ThemePack;
}) =>
{
    const {SystemMessage} = theme.data.ApplicationSettings.GameContainer;

    return (
        <div className="mb-2 drop-shadow-lg">
            <div
                className={`
                    relative
                    mx-6
                    px-2
                    py-1
                    text-base
                    font-poppins
                    text-center
                    skew-x-6
                `}
                style={{
                    backgroundColor: SystemMessage.backgroundColor,
                    color: SystemMessage.textColor,
                }}
            >
                <div className="-skew-x-6">
                    <StyledText
                        theme={theme}
                        text={message}
                    />
                </div>
            </div>
        </div>
    );
};

export default SystemMessage;
